import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './SRFAQs.css';


const AccordionItem = ({ title, content, isOpen, onToggle }) => {
  return (
    <div className="card mb-4">
    <div
      className="card-header"
      onClick={onToggle}
      style={{
        cursor: 'pointer',
        backgroundColor: '#e9ecef',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span
        className="toggle-icon"
        style={{
          marginRight: '10px',
          fontSize: '32px',
          lineHeight: '24px',
          display: 'inline-block',
          width: '32px', // Ensures enough space for the icon
          textAlign: 'center', // Center the icon
        }}
      >
        {isOpen ? '−' : '+'}
      </span>
      <h5 className="mb-0" style={{ flex: 1, margin: 0, lineHeight: '32px' }}>
        {title}
      </h5>
    </div>
    <div className={`collapse ${isOpen ? 'show' : ''}`}>
      <div className="card-body" style={{ backgroundColor: '#f8f9fa' }}>
        {content}
      </div>
    </div>
  </div>
  );
};


const SRFAQs = () => {
 

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const accordionItems = [
    {
      title: 'What types of vehicles are available for rent at SR Car Travels Vijayawada?',
      content: 'SR Car Travels offers an extensive selection of vehicles, including sedans, SUVs, and luxury cars, to accommodate diverse travel requirements and preferences.',
    },
    {
      title: 'Does SR Car Travels offer one way drop services between Vijayawada and other cities?',
      content: 'We provide convenient one-way drop services connecting Vijayawada to major cities such as Hyderabad.',
    },
    {
      title: 'How can I book a car with SR Car Travels Vijayawada?',
      content: 'You can conveniently book a car from the best car rental companies in Vijayawada through our website. ',
    },
    {
      title: 'What are the payment options available for car rentals?',
      content: 'You can complete your purchase using credit/debit cards, net banking, or UPI.',
    },
    {
      title: 'What is your cancellation policy?',
      content: 'Cancellations made at least 24 hours before the scheduled pick-up time qualify for a full refund.',
    },
    {
      title: 'Do you provide 24/7 customer support?',
      content: 'Our devoted customer support team is available 24/7 to help you with bookings, address inquiries, and resolve issues.',
    },
    {
      title: 'Can I modify my booking after it has been confirmed?',
      content: ' Contact our customer service team to easily modify your booking details, such as the rental period or vehicle type. ',
    },
    {
      title: 'Do you offer GPS navigation systems with rental vehicles?',
      content: 'Yes, GPS navigation systems are available in all our car rentals.',
    },
    {
      title: 'Are there any hidden fees or charges?',
      content: ' We uphold a commitment to transparent pricing, ensuring our customers have complete visibility into all fees.',
    },
    {
      title: 'Can I cross state borders with the rental car?',
      content: 'Yes, you are welcome to cross state borders with our rental cars. Just let us know in advance so that we can arrange the required permits and documentation.',
    },
  ];

  
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": accordionItems.map(faq => ({
      "@type": "Question",
      "name": faq.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.answer
      }
    }))
  };


 
  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      </Helmet>
      <div className="container py-5" >


      <h2 className="mb-4 text-center">FAQ</h2>
        
     
      <div className="container mt-5">
        <div className="row">
          {accordionItems.map((item, index) => (
            <div key={index} className="col-md-6">
              <AccordionItem
                title={item.title}
                content={item.content}
                isOpen={openIndex === index}
                onToggle={() => handleToggle(index)}
              />
            </div>
          ))}
        </div>
      </div> 

  
      </div>
    </>
  );
};

export default SRFAQs;
