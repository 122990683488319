import React from 'react';
import tDzireCarImg from '../../assets/img/tDzire1.png';
import tErtigaCarImg from '../../assets/img/tErtiga1.png';
import tInnovaCarImg from '../../assets/img/tInnova.png';
import PackageCard from './PackageCard';
import SRWelcomeBanner from './SRWelcomeBanner';
const packageDetails = [
    {
        title: "Assured Sedan (Dzire/Etios) - 4+1 Seater",
        price: "Rs.5250",
        totalKm: "300",
        extraKmPrice: "12",
        imgSrc: tDzireCarImg,
        altText: "Best Car Travel Services in Vijayawada",
    },
    {
        title: "Assured SUV (Ertiga) - 6+1 Seater",
        price: "Rs.7000",
        totalKm: "300",
        extraKmPrice: "15",
        imgSrc: tErtigaCarImg,
        altText: "Reliable Taxi from Hyderabad to Vijayawada",
    },
    {
        title: "Assured Innova - 7+1 Seater",
        price: "Rs.8500",
        totalKm: "300",
        extraKmPrice: "16",
        imgSrc: tInnovaCarImg,
        altText: "Quality Car Rentals Available in Vijayawada",
        additionalInfo: [
            {
                subtitle: "Total Price For Innova Crysta - 7+1 Seater",
                price: "Rs.10000",
                extraKmPrice: "18",
            }
        ]
    }
];


const SRSpecialPackages = () => (
    <>
        {/* <SRWelcomeBanner /> */}
        <div className="container my-2" >
            <h4 style={{ textAlign: 'center' }}> 
                Our Special Packages Vijayawada ↔ Hyderabad One Way Drop 
            </h4>
            <div className="col-md-8 offset-3 mx-auto">
                <p className='text-muted text-center'>Plan your one-way trip from Vijayawada to Hyderabad with SR <a href="https://srcartravels.in/car-rentals-vijayawada">Car Rental Vijayawada</a> services! Our reliable drivers and comfortable cars make them perfect for business or relocation purposes.</p>
            </div>
            <br />
            <div className="row">
                {packageDetails.map((packageDetail, index) => (
                    <div className="col-md-4" key={index}>
                        <PackageCard {...packageDetail} />
                    </div>
                ))}
            </div>
        </div>
    </>
);

export default SRSpecialPackages;
