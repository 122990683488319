import React from 'react';
import SRHeader from '../components/common/SRHeader';
import SRCallNowButton from '../components/common/SRCallNowButton';
import SrCarTravelsMap from '../components/common/SrCarTravelsMap';
import SRFooter from '../components/common/SRFooter';


const Layout = ({ children }) => {
    return (
        <>
            <SRHeader />
            {/* <SRCallNowButton /> */}
            {children} 
            {/* <SrCarTravelsMap /> */}
            <SRFooter />
        </>
    );
};

export default Layout;
