import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';
import "./KeyWordStyle.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
    bannerImage: require("../assets/img/BannerCarTravelsVijayawada.png"),
    lp1: require("../assets/img/lp2/lp-1.png"),
    lp2: require("../assets/img/lp2/lp-2.png"), 
    lpt1: require("../assets/img/lp2/ic_1.png"),
    lpt2: require("../assets/img/lp2/ic_2.png"),
    lpt3: require("../assets/img/lp2/ic_3.png"),
    lpt4: require("../assets/img/lp2/ic_4.png"), 
    card1: require("../assets/img/lp2/card_1.png"), 
    card2: require("../assets/img/lp2/card_2.png"), 
    card3: require("../assets/img/lp2/card_3.png"), 
    card4: require("../assets/img/lp2/card_4.png"),   
    card5: require("../assets/img/lp2/card_5.png"),     
  };
  
  
  // Custom style for the thin line
  const thinLineStyle = {
    width: "75%",
    margin: "20px auto", // Combined marginTop and marginBottom
    height: "1px",
    backgroundColor: "#ddd",
  };
  
  const data = [
    {
      title:
        "Can I make a last-minute booking?",
      content:
        "We highly recommend booking in advance to ensure the best experience. However, for your convenience, we also accommodate last minute bookings subject to vehicle availability.",
    },
    {
      title: "Is there a cancellation policy?",
      content:
        "Yes, we have a cancellation policy in place. For detailed information, please refer to our website or contact our Customer Service team for assistance.",
    },
    {
      title: "What payment methods do you accept?",
      content:
        " At SR Car Travels, we offer a diverse range of payment options for your convenience. Whether you prefer to use credit/debit cards, make an online transfer, or pay with cash, we have you covered.",
    },
    {
      title: "Do you offer customized sightseeing packages?",
      content:
        "We offer various sightseeing packages that can be personalized to include the attractions and experiences most relevant to your interests. ",
    },
    {
      title:
        "Are your drivers experienced and knowledgeable about local attractions?",
      content:
        " Our drivers are exceptionally experienced and know extensively about Vijayawada's local attractions, rich history, and vibrant culture. ",
    },
  ];
  
  const bookingOptions = [
    {
      imgSrc: imagePaths.card1,
      title: "Kanaka Durgamma Temple",
      text: (
        <>
        The Kanaka Durgamma Temple, situated in
        Vijayawada, Andhra Pradesh, India, stands as a revered
        Hindu temple dedicated to Goddess Kanaka Durga—an
        incarnation of the deity Durga. Recognized as one of
        the most powerful and prominent temples in South
        India, it draws countless devotees from across the
        nation. Perched atop Indrakeeladri Hill with the Krishna
        River embracing three sides of its base, this temple
        boasts architectural splendor and holds profound
        religious significance. As an essential pilgrimage
        center for Hindus, it continues to be a beacon of
        spiritual solace and devotion for many. 
        </>
      ),
    },
    {
      imgSrc: imagePaths.card2,
      title: "Victoria Jubilee Museum",
      text: (
        <span>
        The Victoria Jubilee Museum, also known as the
        Andhra Pradesh State Museum, is an esteemed
        institution in Vijayawada. Established in 1887 to
        commemorate Queen Victoria's Jubilee year, it is one
        of the state's oldest and most significant cultural
        repositories. It boasts an extensive collection of
        artifacts and specimens that showcase the region's
        rich history, culture, and heritage. Book a comfortable
        ride with SR Car Travels Vijayawada for a memorable
        journey
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card3,
      title: "Panakala Lakshmi Narasimha Temple",
      text: (
        <span>
            The Panakala Lakshmi Narasimha Temple, situated in
            Mangalagiri, Andhra Pradesh, India, is a renowned Hindu
            temple dedicated to Lord Narasimha, an incarnation of
            Lord Vishnu. This sacred site is distinguished by its
            unique worship practice of offering Panakam, a sweet
            pudding made from jaggery and flour. Visitors to the
            temple are captivated not only by its spiritual
            significance but also by this distinctive tradition that
            enriches the region's cultural heritage.
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card4,
      title: "Amaravathi Temple",
      text: (
        <span>
            Nestled in the village of Amaravathi, near Vijayawada in
            Andhra Pradesh, India, the Amaravathi Temple stands
            as a timeless testament to ancient Hindu architecture
            and spirituality. This most hallowed shrine. Its
            historical significance and religious importance
            continue to draw revered sanctuary is dedicated to
            Lord Shiva and is celebrated as one of the region's
            oldest and devotees and admirers from across the
            world. 
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card5,
      title: "Undavalli Caves",
      text: (
        <span>
            The Undavalli Caves, an exquisite testament to ancient
            rock cut Hindu temples, are nestled in the serene
            village of Undavalli, near Vijayawada in Andhra
            Pradesh, India. Revered for their architectural brilliance,
            these caves stand as one of the finest exemplars of
            Indian rock cut craftsmanship. Historians and
            archaeologists believe that they were meticulously
            constructed between the 4th & 6th centuries AD.
        </span>
      ),
    } 
  ];

  
const SRCarTravelsVijayawada = ({ BannerTitle }) => {

    const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

    const handleToggle = (index) => {
      setActiveKeys(
        (prevKeys) =>
          prevKeys.includes(index)
            ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
            : [...prevKeys, index], // Open the accordion if it's closed
      );
    };
  
    const toggleIcon = (index) => {
      return activeKeys.includes(index) ? (
        <i className="fa fa-minus" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-plus" aria-hidden="true"></i>
      );
    };

    return (
    <>
    <Helmet>
        <title>Car Travels Vijayawada | Cabs in Vijayawada | SR Car Travels</title>
        <meta name="description" content="Discover worry-free car travels in Vijayawada with SR Car Travels. Enjoy experienced drivers, 24x7 support, no hidden charges, flexible options, top-notch maintenance." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://srcartravels.in/car-travels-vijayawada" />

        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta property="og:title" content="Car Travels Vijayawada | Cabs in Vijayawada | SR Car Travels" />
        <meta property="og:description" content="Discover worry-free car travels in Vijayawada with SR Car Travels. Enjoy experienced drivers, 24x7 support, no hidden charges, flexible options, top-notch maintenance." />
    </Helmet> 
    
    <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
        <h1 className="mb-3 text-center banner-title-h1">
        {BannerTitle} 
        </h1>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.lp1}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
              <h2 class="fw-bold">
              Discover the Best Destinations Around Andhra Pradesh with SR Car Travels Vijayawada
              </h2>
              <p className="fs-6">
              Have you ever dreamt of a journey that transcends the typical tourist trail? Imagine leaving the crowds behind and embarking on an adventure that unveils the soul of Vijayawada. Picture yourself traversing landscapes painted with vibrant hues, from the emerald embrace of the Nallamala Hills to the sapphire shimmer of the Bay of Bengal. SR Car Travels Vijayawada extends its hand as your trusted travel companion, ready to unlock the city's best destinations
              </p>
              <p className="fs-6">
              Whether you're a history buff yearning to decipher secrets from bygone eras, a nature enthusiast seeking solace in pristine landscapes, or a culture appreciator eager to savor Andhra's essence, <a href="https://srcartravels.in/">SR Car Travels</a> curates itineraries tailored perfectly for your desires.
              </p>
            </div>
          </div>
        </div>
      </div>

      

      <div className="container py-4">
        <h2 className="mb-4 text-center fs-3 fw-bold">
        Unveil the Enchantment of Vijayawada: Must-Visit Places on Your City Tour
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {bookingOptions.map((option, index) => (
            <div key={index} className="col">
              <div className="card h-100 border-0">
                <LazyLoadImage
                  src={option.imgSrc}  
                  alt={option.title}
                  effect="blur"
                  className="card-img px-2"
                />
                <div className="card-body text-left">
                  <h3 className="card-title fs-5">{option.title}</h3>
                  <p className="card-text fs-6">{option.text}</p>
                </div>
              </div>
              
            </div>
            
          ))}

            <div   className="col " >
                <div className="card h-100 border-0 align-middle"  style={{ backgroundColor:'#d9d5d5',padding: '50% 4%',borderRadius: '30px',textAlign:'center' }}>
         
                <div className="card-body">
                    <h3 className="card-title fs-5 ">Learn More About The Effortless
                    Sightseeing In Vijayawada</h3>
                    <a
                    className="btn btn-info text-white bg-dark my-3 "
                    style={{ borderRadius: "20px", border: "none" }}
                    >
                    Learn More
                  </a>
                </div>
                </div>
                
            </div>


        </div> 
      </div>



      <h2 className="text-center mb-4 fw-bold fs-3 mt-5">
      Effortless Sightseeing in Vijayawada: How SR Car<br/> Travels Makes it Easy
      </h2>
      <div className="container mt-5">
        {/* Outstation Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt1}
              alt="cab services in vijayawada: outstation"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Comfortable & WellMaintained Vehicles</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Our fleet comprises modern, clean, and meticulously
maintained vehicles to ensure your comfortable and enjoyable
journey
            </p> 
          </div>
        </div>

        <hr style={thinLineStyle} />{" "}
        {/* Thin line after Outstation Services Section */}
        {/* Local Cab Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt2}
              alt="Local Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Experienced Drivers</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            SR Car Travels Vijayawadas seasoned and expert drivers are
well versed in navigating the city's best routes and premier
local attractions. Their extensive knowledge offers valuable
insights into the area's history and culture, enriching your
journey with fascinating information
            </p>
          </div>
        </div>
        <hr style={thinLineStyle} />{" "}

        {/* Thin line after Local Cab Services Section */}
        {/* Airport Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt3}
              alt="Airport Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Affordable Packages</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            We offer a range of car packages designed to suit different
budgets and group sizes. We believe in transparent pricing,
ensuring there are zero hidden charges.
            </p>
          </div>
        </div>


        <hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt4}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">24/7 Customer Support</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Experience unparalleled support with our 24/7 customer
service, ready to assist you with any queries or changes in
your plans. Whether you're facing travel related concerns or
emergencies, count on a swift and efficient response from our
dedicated team.
    </p>
  </div>
</div>


<hr style={thinLineStyle} />{" "}
 


      </div>



      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.lp2}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                Upgrade Your Sightseeing Experience: Choose
                SR Car Travels in Vijayawada
                </h3>
                <p className="fs-6">
                Selecting SR Car Travels in Vijayawada guarantees a seamless and delightful sightseeing
experience. Our service provides personalized itineraries tailored to your interests, enabling you to
explore the city's prominent landmarks and hidden treasures
                </p>

                <p className="fs-6">
                With our fleet of comfortable, air conditioned, and meticulously maintained vehicles, you can travel
in style and comfort. Our experienced drivers are not only courteous but also knowledgeable about
Vijayawadas rich history and culture, adding depth to your journey. We offer affordable packages
with transparent pricing—no hidden costs involved.
                </p>

                <p className="fs-6">
                Enjoy a hassle free experience with SR Car Travels Vijayawadas convenient booking process, flexible pick up/drop off options, and punctually reliable service—all designed to help you make the most of your sightseeing time
                </p>

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a
                    className="btn btn-info text-white bg-dark "
                    style={{ borderRadius: "20px", border: "none" }}
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-4 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
        </>
    );
};

export default SRCarTravelsVijayawada;
