import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import "../LandingPages/InsideGuideInNavigatingVijayawada.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
  bannerImage: require("../assets/img/lp1/l1.png"),
  lp1: require("../assets/img/lp3-1.png"),
  lp2: require("../assets/img/lp3-2.png"),
  lp3: require("../assets/img/lp3-3.png"), 
  lpt1: require("../assets/img/lpt3-1.png"),
  lpt2: require("../assets/img/lpt3-2.png"),
  lpt3: require("../assets/img/lpt3-3.png"),
  lpt4: require("../assets/img/lpt3-4.png"),  
};

// Custom style for the thin line
const thinLineStyle = {
  width: "75%",
  margin: "20px auto", // Combined marginTop and marginBottom
  height: "1px",
  backgroundColor: "#ddd",
};

const data = [
  {
    title: "How can I book a driver-led cab with SR Car Travels?",
    content: "Booking a driver-led cab with SR Car Travels is seamless. You can conveniently book online through our website /contact our customer support team for personalized assistance.",
  },
  {
    title: "Are the drivers at SR Car Travels experienced and licensed?",
    content: "All our drivers at SR Car Travels are highly experienced and fully licensed, and they undergo rigorous background checks to guarantee the safety and comfort of our passengers.",
  },
  {
    title: "Can I request a specific type of vehicle for my trip?",
    content: "At the time of booking, you can specify your vehicle preference, and we will make every effort to fulfill your request based on availability.",
  },
  {
    title: "What are the operating hours for the driver-led cab service?",
    content: "Our driver-led cab service operates around the clock, ensuring you can book a ride at any time of the day or night, according to your convenience.",
  },
  {
    title: "Do I need to pay extra for the driver s services?",
    content: " The cost of the driver's services is already included in the overall fare of the cab. You won't incur any additional charges for the driver's services.",
  }
  
];

const InsideGuideInNavigatingVijayawada = ({BannerTitle}) => {
  const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

  const handleToggle = (index) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(index)
          ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
          : [...prevKeys, index], // Open the accordion if it's closed
    );
  };

  const toggleIcon = (index) => {
    return activeKeys.includes(index) ? (
      <i className="fa fa-minus" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-plus" aria-hidden="true"></i>
    );
  };

  return (
    <>
      <Helmet>
        <title>Car Travellers Near Me  | SR Car Travels</title>
        <meta
          name="description"
          content="Get SR Car Travels for reliable cab service when you search car travellers near me. Expect convenience, reliability, and unmatched service."
        />
        <link
          rel="canonical"
          href="https://srcartravels.in/car-travellers-near-me"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta
          property="og:title"
          content="Car Travellers Near Me  | SR Car Travels"
        />
        <meta
          property="og:description"
          content="Get SR Car Travels for reliable cab service when you search car travellers near me. Expect convenience, reliability, and unmatched service."
        />
      </Helmet>
      <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
        <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
        </h1>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.lp1}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
              <h2>
              Understanding the SR Car Travels’
              Advantage For Stress-Free Commute
              </h2>
              <p className="fs-6 ">
              When planning your next trip to Vijayawada, your mind swirls with visions of bustling streets,
fragrant spice markets, and ancient temples steeped in history. However, navigating the city's
chaotic hustle may dampen your excitement. This is where SR Car Travels steps in, offering more
than just a stress-free travel experience. Their services promise to transform your journey into a
relishing adventure, allowing you to explore the <a href="https://srcartravels.in/vijayawada-city-tour">wonders of Vijayawada</a> at your own pace and in
complete comfort.
              </p>
              <p className="fs-6">
              No more battling rush hour traffic, understanding bus routes, or struggling with taxis. SR Car Travels
seeks to provide you with a seamless personalized journey, promising to make your exploration of
Vijayawada stress-free. When you search for “car travellers near me”, you get the SR Car Travels’
name at the top, which can be your guide to a truly stress-free adventure in Vijayawada.
              </p>
             
            </div>
          </div>
        </div>
      </div>
   
      
 
 

      <h2 className="text-center mb-4 fw-bold fs-3 py-5">
      Why Choose SR Car Travels Over Self-Drives?
      </h2>
      <div className="container">
        {/* Outstation Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt1}
              alt="cab services in vijayawada: outstation"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Eliminates the Stress of<br/>
            Navigation</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            At SR Car Travels, our drivers are equipped with extensive local
knowledge and expertise to ensure your seamless journey to
your destination. They have an in-depth understanding of the
city's routes, shortcuts, and peak traffic times, allowing you to
unwind and leave the driving to them with full confidence. 
            </p> 
          </div>
        </div>

        <hr style={thinLineStyle} />{" "}
        {/* Thin line after Outstation Services Section */}
        {/* Local Cab Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt2}
              alt="Local Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Relax and Enjoy the Journey
            </h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            With <a href="https://srcartravels.in/vijayawada-city-tour">SR Car Travels</a>, comfort and relaxation are our top
priorities. Passengers can unwind and take in the sights
without the hassle of driving, navigating, or parking. Our
professional drivers care for everything, ensuring a smooth
and enjoyable ride. Whether traveling for business or leisure,
you can relax and fully appreciate your journey
            </p>
          </div>
        </div>
        <hr style={thinLineStyle} />{" "}

        {/* Thin line after Local Cab Services Section */}
        {/* Airport Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt3}
              alt="Airport Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Safety First</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            At SR Car Travels, safety is our foremost concern. Our team of
professional drivers, each with a proven track record,
guarantees you a secure and worry-free journey. Rest assured,
our experienced drivers are well-trained to handle all road
situations, ensuring a smooth and stress-free travel experience
for you and your loved ones. With SR Car Travels, you can relax
and enjoy the journey with complete peace of mind.
            </p>
          </div>
        </div>


        <hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt4}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">All-inclusive Travel Experience</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Experience true all-inclusive comfort with SR Car Travels. We
handle everything from the moment you book your ride by
searching car travellers near me and getting our name as
your option until your journey's end. Our transparent pricing
and absence of hidden fees allow you to enjoy your travel
worry-free. Trust SR Car Travels for a truly hassle-free
experience from start to finish.
    </p>
  </div>
</div>


 


      </div>


      <hr style={thinLineStyle} />{" "}



      <div class="container-fluid px-5 my-5"    style={{ backgroundColor: '#113042',color:'#fff',  paddingTop: '60px', paddingBottom: '60px' }} >

<div className="row align-items-center">
  
   <div className="col-md-7 my-2 p-5">
   <h3 className="text-left py-3 fs-3 fw-bold">
   Discover SR Car Travels Feature-Rich Cabs
   </h3>


   <ul className="fs-6">
            
            <li>
            SR Car Travels offers the optOon to hOre cabs that can accommodate 3-4 passengers 
or 6-E passengers 
            </li>
            <li>
            All our cabs have ample legroom and headroom, allowOng passengers to stretch out 
            & relax throughout the journey.
            </li>
            <li>ExperOence the freedom to move comfortably wOthout any sense of confOnement, 
            guaranteeOng a more pleasurable travel experOence</li>
            <li> Our cabs boast a metOculously maOntaOned aOr condOtOonOng system, guaranteeOng 
            peak performance and year-round comfort</li>
            <li>The cab seats are crafted wOth ergonomOc desOgn to ensure passengers receOve 
optOmal support and comfort, effectOvely reducOng straOn on the body durOng travel. 
You can enjoy a smooth and ultra-comfortable rOde, even on rough roads</li>
          </ul>
 
   </div>

   <div className="col-md-5 ">
     <LazyLoadImage
       src={imagePaths.lp2}
       alt="Vijayawada Road Trip Ideas"
       effect="blur"
       width="100%"
       height="auto"
       style={{ borderRadius: "25px" }}
       className="img-fluid"
     />
   </div>
 </div>



</div>


 


      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
 

        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.lp3}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                Beyond Convenience: Your All-Season Luxury
                Travel Partner in Vijayawada
                </h3>
                <p className="fs-6">
                Traveling in a chauffeur-driven <a href="https://srcartravels.in/cabs-in-vijayawada">cab in Vijayawada</a> has long been synonymous with luxury and
sophistication. At SR Car Travels, we strive to be your year-round travel companion for local and
outstation rides. Our <a href="https://srcartravels.in/cabs-in-vijayawada">car rental options</a> include sedans, mid-sized vehicles, full-sized vehicles, luxury
cars, and 4-seater and 6 7-seater cars. 
                </p>

                <p className="fs-6">
                Rest assured, our fleet comprises robust, well-maintained cars that undergo regular sanitization in
                adherence to the COVID 19 protocols mandated by the government.
                </p>

                <p className="fs-6">
                When you choose to hire a cab from the car travellers near me search, you get the name SR Car
Travels. You can expect convenience, reliability, and unparalleled service. While numerous car rental
companies in Vijayawada may be content for your attention, our cab rental services stand out for
their comprehensive and seamless experience, starting when you book with us.
                </p>

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a className="btn btn-info text-white bg-dark "  style={{ borderRadius : '20px', border: 'none' }}>Know More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-3 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InsideGuideInNavigatingVijayawada;
