import React, { useState } from 'react';
import { NavLink, Link,useLocation } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import bannerImage from '../../Banner/logo_original.png';
import '../../features/features.css';

function SRHeader() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [activeLink, setActiveLink] = React.useState("/");
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const location = useLocation();

  return (
    <>
      <div className="sticky-top">
        <nav 
          defaultActiveKey={activeLink}
          onSelect={(selectedKey, event) => {
            event.preventDefault();
            console.log("selected key is: ", selectedKey);
            setActiveLink(selectedKey);
          }}
        className={`navbar navbar-expand-lg navbar-dark custom-navbar-bg navbar-custom`} style={{ padding: 0 }}>
          <div className="container-fluid" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Link className="navbar-brand" to="/" onClick={handleNavCollapse} style={{ marginLeft: 0, padding: 0 }} >
              <img loading="lazy" src={bannerImage} alt="SR Car Travels" className="sr-car-logo-header d-inline-block" style={{ height: '75px', width: '320px' }} />
            </Link>
            <button className="navbar-toggler" type="button" onClick={handleNavCollapse}
              aria-controls="navbarNav" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation"> 
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`navbar-collapse justify-content-end ${isNavCollapsed ? 'collapse' : ''}`}
              id="navbarNav"
            >
              
              <ul className="navbar-nav">
              <li className="nav-item mx-3  d-none d-md-block" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/" eventKey="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item mx-3  d-none d-md-block" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/about" eventKey="/about">
                    About
                  </NavLink>
                </li>
                <li className="nav-item mx-3  d-none d-md-block" onClick={handleNavCollapse}>
                <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item  >
                <NavLink
                className={`nav-link ${location.pathname === '/vijayawada-city-tour' ? 'active' : 'text-dark'}`}
                to="/vijayawada-city-tour"
                
                >
                Vijayawada City Tour  
                </NavLink> 
                </NavDropdown.Item>
                <NavDropdown.Item>
                <NavLink
                className={`nav-link ${location.pathname  === '/pancharama-kshetras' ? 'active' : 'text-dark'}`}
                to="/pancharama-kshetras" 
                >
                Pancharama-Kshetras
                </NavLink>
                </NavDropdown.Item> 
                <NavDropdown.Item>
                <NavLink
                className={`nav-link ${location.pathname  === '/outstation' ? 'active' : 'text-dark'}`}
                to="/outstation" 
                >
                Outstation
                </NavLink>
                </NavDropdown.Item> 
                <NavDropdown.Item >
                <NavLink
                className={`nav-link ${location.pathname  === '/sp-offers' ? 'active' : 'text-dark'}`}
                to="/sp-offers" 
                >Special Offers
                </NavLink>
                </NavDropdown.Item> 
                </NavDropdown>
                  
                </li>
                <li className="nav-item mx-3  d-none d-md-block" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/contact-us">
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item mx-4  d-none d-md-block" >
                  <a href="https://srcartravels.in/login" className="btn btn-info text-white"><i class="fas fa-user text-white mr-3 pe-2 text-info"></i> Login</a>
                </li>
                {/* <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/vijayawada-city-tour">
                    Vijayawada City Tour
                  </NavLink>
                </li>
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/pancharama-kshetras">
                    Pancharama-Kshetras
                  </NavLink>
                </li>
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/outstation">
                    OutStation
                  </NavLink>
                </li>
                <li className="nav-item" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/sp-offers">
                    Special Offers
                  </NavLink>
                </li>
                <li className="nav-item me-2" onClick={handleNavCollapse}>
                  <NavLink className="nav-link" activeClassName="active" to="/login">
                    Login
                  </NavLink>
                </li> */}
              </ul>

          

              <Navbar bg="light" expand="lg" className="d-block d-md-none">   
		
			<Nav className="me-auto">
			  <NavLink className="nav-link bg-white text-dark px-3" activeClassName="active" to="/" eventKey="/" style={{ borderBottom: '1px solid #aeaeae',borderRadius:'0px !important' }}  onClick={handleNavCollapse}>
			  Home
			  </NavLink>
			  <NavLink className="nav-link bg-white text-dark px-3" activeClassName="active" to="/about" eventKey="/about" style={{ borderBottom: '1px solid #aeaeae',borderRadius:'0px !important' }}  onClick={handleNavCollapse}>
			  About
			  </NavLink>
			  
			  {/* Multi-level dropdown */}
			  <NavDropdown title="Services" id="basic-nav-dropdown" className="bg-white  px-3" style={{ borderBottom: '1px solid #aeaeae',borderRadius:'0px !important' }}>
			  <NavDropdown.Item  >
					<NavLink
					className={`nav-link bg-white ${location.pathname === '/vijayawada-city-tour' ? 'active' : 'text-dark'}`}
					to="/vijayawada-city-tour"  onClick={handleNavCollapse}
					
					>
					Vijayawada City Tour  
					</NavLink> 
					</NavDropdown.Item>
					<NavDropdown.Item>
					<NavLink
					className={`nav-link bg-white ${location.pathname  === '/pancharama-kshetras' ? 'active' : 'text-dark'}`}
					to="/pancharama-kshetras"   onClick={handleNavCollapse}
					>
					Pancharama-Kshetras
					</NavLink>
					</NavDropdown.Item> 
					<NavDropdown.Item>
					<NavLink
					className={`nav-link bg-white ${location.pathname  === '/outstation' ? 'active' : 'text-dark'}`}
					to="/outstation"  onClick={handleNavCollapse}
					>
					Outstation
					</NavLink>
					</NavDropdown.Item> 
					<NavDropdown.Item >
					<NavLink
					className={`nav-link bg-white ${location.pathname  === '/sp-offers' ? 'active' : 'text-dark'}`}
					to="/sp-offers"  onClick={handleNavCollapse}
					>Special Offers
					</NavLink>
					</NavDropdown.Item> 
			  </NavDropdown>

			  <NavLink className="nav-link bg-white text-dark px-3" activeClassName="active" to="/contact-us" style={{ borderBottom: '1px solid #aeaeae',borderRadius:'0px !important' }}  onClick={handleNavCollapse}>
			  Contact
			  </NavLink>

        <NavLink className="nav-link bg-white px-3" activeClassName="active" to="/login" eventKey="/about" style={{ borderBottom: '1px solid #aeaeae' }}  onClick={handleNavCollapse}>
        <a href="https://srcartravels.in/login" className="text-decoration-none text-dark">Login</a>
			  </NavLink>
 
			</Nav> 
		</Navbar>

   
 
            </div>



          </div>
        </nav>

        {/* <hr className="rounded" /> */}
      </div>
      {/* <div style={{padding: 5}} className={`text-end me-1 ${isNavCollapsed ? 'hidden' : ''}`}>
        <a className="d-block mb-0 fw-bold text-purple" href="tel:+918886556632" style={{ textDecoration: 'none', fontSize: '1.2rem' }}>
          CALL US: (+91) 8886556632
        </a>
        <span className="d-block text-red">24/7 EVERY DAY</span>
      </div> */}
    </>
  );
}

export default SRHeader;
