import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SRContact.css';
import '../../features/About.css'; 
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component'; 

const imagePaths = {  
  bannerImage: require("../../assets/img/c-1.png"), 
  callMobileImage: require("../../assets/img/callMobile.png")
};



const SRContactUs = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mobNumber: '',
    name: 'test',
    email: 'customer@gmail.com', // Default or gathered from the form
    // Add other fields as necessary
  });

  // State for submission message
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
 
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const contactForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://srcartravels.in/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        const data = await response.text(); // Use .text() initially to avoid JSON parsing issues
        setIsSuccess(true);
        setMessage('Form submitted successfully.');
        navigate('/thank-you');

      } else {
        // The server responded with an error status
        const errorText = await response.text(); // Get the response text to display or log
        console.error('Server responded with an error:', errorText);
        setIsSuccess(false);
        setMessage('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setIsSuccess(false);
      setMessage('An error occurred. Please try again.');
    }
  };





  return (
    <>
    <Helmet>
        <title>Contact Us - SR Car Travels Vijayawada</title>
        <meta name="description" content="Contact SR Car Travels in Vijayawada for reliable transportation solutions. Our dedicated team is eager to assist you with your travel inquiries and bookings" />
        <link rel="canonical" href="https://srcartravels.in/contact-us" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="Car Travels in Vijayawada, Car Travels Vijayawada, Car Travel in Vijayawada, Best Car Travels in Vijayawada, Car Travellers Near Me, Car Rentals Vijayawada" />
        
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta property="og:title" content="Car Travels in Vijayawada | Car Rentals in Vijayawada |SR Car Travels" />
        <meta property="og:description" content="Discover seamless car travels in Vijayawada and beyond with SR Car Travels. Whether you need car rentals, cabs, or personalized travel packages, we've got you covered." />
    </Helmet>

    <div class="callus-btn">
        <a href="tel:8886556632" target="_blank">
        <img src={imagePaths.callMobileImage} className="banner-backdrop d-block w-100"  loading="lazy" /> Call Us
        </a>
    </div>  

    <div class="whatsapp-btn">
        <a href="https://wa.me/9346397115" target="_blank">
        <i class="bi bi-whatsapp"></i> WhatsApp
        </a>
    </div>

    <div className="abt-contents-container"> 
        <div>
            <img src={imagePaths.bannerImage} className="banner-backdrop d-block w-100"  loading="lazy" />
            <h1 className="mb-3 text-center banner-title-h1">
                Contact Us
                </h1>
            <div className=" py-3"></div>
        </div> 
    </div>

    <div className="container-fluid  align-items-center  p-5">
      <div class="row">
      {/* Content column */}
      <div class="col-md-6 px-5 my-auto content-section">
        <div class="icon-text">
          <i class="fas fa-map-marker"></i>
          <div class="text-content">
            <p><b>Address:</b></p>
            <p>LIG 34, Ground Floor, APIIC Colony, Zamac Apartment</p>
            <p>Road, APIIC Colony Road, Guru Nanak Colony, Vijayawada,</p>
            <p>Andhra Pradesh 520007.</p>
          </div>
        </div>

        <div class="icon-text">
          <i class="fas fa-phone"></i>
          <div class="text-content">
            <p><b>Phone:</b></p>
            <p><a href="tel:8886556632" class="text-dark text-decoration-none">+91-8886556632</a></p>
          </div>
        </div>

        <div class="icon-text">
          <i class="fas fa-envelope"></i>
          <div class="text-content">
            <p><b>Email:</b></p>
            <p><a href="mailto:contact@srcartravels.in" class="text-dark text-decoration-none">contact@srcartravels.in</a></p>
          </div>
        </div>

      </div>

      {/* Image column */}
      <div className="col-md-5  ">
      <form id="form" onSubmit={contactForm}>
                    <div className="card border-0 bg-rounded-0 bg-light">
                 
                      <div className="card-body p-5 border">
                        {/* Name Field */}
                        <h3 className="py-3 text-center">Leave a Message</h3>
                        <div className="mb-3 text-center">
                          <div className="input-group"> 
                            <input type="text" className="form-control" placeholder="Full Name" name="name" onChange={handleChange} />
                          </div>
                        </div>
                        {/* Phone Field */}
                        <div className="mb-3 text-center">
                          <div className="input-group"> 
                            <input type="text" className="form-control" pattern="[6789][0-9]{9}" placeholder="+91 Phone" name="mobNumber" required onChange={handleChange} />
                          </div>
                        </div>
                        {/* Email Field */}
                        <div className="mb-3 text-center ">
                          <div className="input-group"> 
                            <input type="email" className="form-control" placeholder="Email" name="email" onChange={handleChange} />
                          </div>
                        </div>
                        <div className="mb-3 text-center ">
                          <div className="input-group"> 
                          <textarea placeholder="Message" class="form-control" rows="3"></textarea> 
                          </div>
                        </div>
                        {/* Submit Button */}
                        <div className="text-left">
                          <input type="submit" value="Submit" className="btn btn-info btn-block rounded-0 py-2 text-white" />
                        </div>
                      </div>
                    </div>
        </form>
      </div>
      </div>



    
    </div>


    <div class="container-fluid bg-light py-5">
      <div class="row">
        <h2 className='text-center pb-4'>Map Location</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d61207.51335938589!2d80.6298875!3d16.5023742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35fb8e656cf61d%3A0xe4d4203c2e9acc69!2sSR%20Car%20Travels!5e0!3m2!1sen!2sin!4v1721910449401!5m2!1sen!2sin"
        width="100%" 
        height="410"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
        title="SR Car Travels In Vijayawada"
        ></iframe>
      </div>
      </div>
</>
  );
};

export default SRContactUs;
