import React from 'react';
import { Helmet } from 'react-helmet';  
import '../../features/About.css'; 
import { LazyLoadImage } from 'react-lazy-load-image-component';

const imagePaths = {  
    bannerImage: require("../../assets/img/a-1.png"),
    aboutRentals1 : require("../../assets/img/a-2.png"),
    aboutRentals2 : require("../../assets/img/a-3.png"),
    aboutRentals3 : require("../../assets/img/a-4.png"),
    callMobileImage: require("../../assets/img/callMobile.png")
    
};



const About = () => {
    return (
    <>
    <Helmet>
        <title>About Us | A Few Words About SR Car Rentals Vijayawada</title>
        <meta name="description" content="SR Car rentals in Vijayawada prioritize your safety and comfort. Our team of experts and courteous drivers is well-versed in the local roads, ensuring a smooth and efficient drive" />
        <link rel="canonical" href="https://srcartravels.in/about" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="Car Travels in Vijayawada, Car Travels Vijayawada, Car Travel in Vijayawada, Best Car Travels in Vijayawada, Car Travellers Near Me, Car Rentals Vijayawada" />
        
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/about" />
        <meta property="og:title" content="About Us | A Few Words About SR Car Rentals Vijayawada" />
        <meta property="og:description" content="SR Car rentals in Vijayawada prioritize your safety and comfort. Our team of experts and courteous drivers is well-versed in the local roads, ensuring a smooth and efficient drive" />
    </Helmet>
    <div class="callus-btn">
        <a href="tel:8886556632" target="_blank">
        <img src={imagePaths.callMobileImage} className="banner-backdrop d-block w-100"  loading="lazy" /> Call Us
        </a>
    </div>  

    <div class="whatsapp-btn">
        <a href="https://wa.me/9346397115" target="_blank">
        <i class="bi bi-whatsapp"></i> WhatsApp
        </a>
    </div>

    <div className="abt-contents-container"> 
        <div>
            <img src={imagePaths.bannerImage} className="banner-backdrop d-block w-100"  loading="lazy" />
            <h2 className="mb-3 text-center banner-title-h1">
                About Us
                </h2>
            <div className=" py-3"></div>
        </div> 
    </div>

    <div className="container">
        <div className="row align-items-center  ">

            {/* Content column */}
            <div className="col-md-6 my-2 p-5">
            <h1>A Few Words About SR Car Rentals
            Vijayawada</h1>
            <p className="fs-6">
            Planning a trip in or out of Vijayawada or need a reliable ride across
            the city? Your search ends with <a href="https://srcartravels.in/">SR Car Travels</a>, your ultimate
            solution for comfortable and convenient car rentals. Whether you're
            exploring the vibrant streets of Vijayawada in a compact hatchback
            or embarking on a family adventure to Hyderabad in a spacious
            SUV, SR Car Travels in Vijayawada offers a diverse fleet to meet your
            needs. Each car is meticulously maintained, ensuring a smooth and
            safe journey.  SR Car rentals in Vijayawada prioritize your safety and
            comfort. Our team of experts and courteous drivers is well-versed
            in the local roads, ensuring a smooth and efficient drive. Sit back,
            relax, and revel in the scenic landscapes while your professional
            driver navigates the city.
            </p>


            </div>

            {/* Image column */}
            <div className="col-md-6 px-5">
                <LazyLoadImage
                    src={imagePaths.aboutRentals1}
                    alt="Car Rentals in Vijayawada"
                    effect="blur"
                    width="100%"
                    height="auto"
                    style={{ borderRadius: "25px" }}
                    className="img-fluid "
                />
            </div>
        </div> 
    </div>

    <div className="container-fluid bg-light">
    <div className="container ">
        <div className="row align-items-center ">

            {/* Image column */}
            <div className="col-md-6 px-5">
                <LazyLoadImage
                    src={imagePaths.aboutRentals2}
                    alt="Car Rentals in Vijayawada"
                    effect="blur"
                    width="100%"
                    height="auto"
                    style={{ borderRadius: "25px" }}
                    className="img-fluid "
                />
            </div>


            {/* Content column */}
            <div className="col-md-6 my-2 p-5">
            <h3>SR Car Rentals Vijayawada</h3>
            <p className="fs-6">
            At SR <a href="https://srcartravels.in/car-travels-vijayawada">Car Travels Vijayawada</a>, we prioritize customer satisfaction
            above all else. Our dedicated team is committed to addressing any
            concerns and ensuring that your journey, from booking to drop-off,
            is seamless. You can expect on-time service, clear communication,
            and a focus on making your experience in Vijayawada truly
            memorable.
            </p>

            <p className="fs-6">
            But we at SR <a href="https://srcartravels.in/car-rentals-vijayawada">Car Rentals Vijayawada</a> offer more than just rental
            cars; we offer peace of mind. With our reliable services, comfortable
            vehicles, and unwavering commitment to customer satisfaction, you
            can lay your focus on creating lasting memories while we take care
            of the rest.
            </p>
 

            </div>

        </div> 
    </div>
    </div>

    <div className="container py-5">
    <a href="https://srcartravels.in/car-rentals-vijayawada">
    <LazyLoadImage
                    src={imagePaths.aboutRentals3}
                    alt="Car Rentals in Vijayawada"
                    effect="blur"
                    width="100%"
                    height="auto"
                    style={{ borderRadius: "25px" }}
                    className="img-fluid "
                />
    </a>
    </div>

        </>
    );
};

export default About;
