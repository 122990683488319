import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import "../LandingPages/InsideGuideInNavigatingVijayawada.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
  bannerImage: require("../assets/img/lp1/l1.png"),
  tileImage: require("../assets/img/b1-1.png"),
  tileImage1: require("../assets/img/lp1/b2.png"),
  tileImage2: require("../assets/img/lp1/b3.png"),
  aboutRentals3: require("../assets/img/a-4.png"),
  lpt1: require("../assets/img/lpt-1.png"),
  lpt2: require("../assets/img/lpt-2.png"),
  lpt3: require("../assets/img/lpt-11.png"),
  lpt4: require("../assets/img/lpt-3.png"),
  lpt5: require("../assets/img/lpt-4.png"), 
};

// Custom style for the thin line
const thinLineStyle = {
  width: "75%",
  margin: "20px auto", // Combined marginTop and marginBottom
  height: "1px",
  backgroundColor: "#ddd",
};

const data = [
  {
    title: "What types of vehicles are available for rent?",
    content: "At SR Car Rentals, we provide a diverse range of vehicles, catering to various travel requirements and group sizes. Our selection includes compact cars, sedans, SUVs, and luxury cars.",
  },
  {
    title: "How can I book a car rental?",
    content: "You can easily reserve a car rental through our user-friendly online booking system on our website or by getting in touch with our 24/7 customer support team.",
  },
  {
    title: "What are the rental rates?",
    content: "Our rental rates are tailored to the type of vehicle and duration of the rental. We are dedicated to providing competitive pricing to guarantee you receive the best value for your investment.",
  },
  {
    title: "Do you offer chauffeur-driven car services?",
    content: "We provide a comprehensive range of car options, including self-drive and chauffeur-driven choices, to meet your preferences",
  },
  {
    title: "Are the vehicles insured?",
    content: "All our vehicles are fully insured. We recommend reviewing the insurance terms and coverage details when booking to ensure complete understanding",
  }
  
];

const InsideGuideInNavigatingVijayawada = ({BannerTitle}) => {
  const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

  const handleToggle = (index) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(index)
          ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
          : [...prevKeys, index], // Open the accordion if it's closed
    );
  };

  const toggleIcon = (index) => {
    return activeKeys.includes(index) ? (
      <i className="fa fa-minus" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-plus" aria-hidden="true"></i>
    );
  };

  return (
    <>
      <Helmet>
        <title>Car Rentals Vijayawada | SR Car Travels</title>
        <meta
          name="description"
          content="SR Car Rentals Vijayawada offers budget-friendly car rentals, letting you explore the city affordably. Discover Vijayawada without overspending!"
        />
        <link
          rel="canonical"
          href="https://srcartravels.in/car-rentals-vijayawada"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta
          property="og:title"
          content="Car Rentals Vijayawada | SR Car Travels"
        />
        <meta
          property="og:description"
          content="SR Car Rentals Vijayawada offers budget-friendly car rentals, letting you explore the city affordably. Discover Vijayawada without overspending!"
        />
      </Helmet>
      <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
        <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
        </h1>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.tileImage}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
              <h2>
                The Insider's Guide to Navigating Vijayawada With SR Car Rentals
                <br /> Vijayawada Services
              </h2>
              <p className="fs-6 ">
                Vijayawada is a city where ancient whispers mingle with modern
                marvels, serene canals travel through bustling streets, and the
                aroma of filter coffee mingles with the salty tang of the
                Krishna River. It's a city that deserves to be explored,
                savored, and truly experienced. But navigating its vibrant
                tapestry can be daunting, especially for first-time visitors.
              </p>
              <p className="fs-6">
                This is where SR Car Rentals Vijayawada steps in – not just as
                your car rental service but as your trusted travel companion.
                With the freedom to explore at your own pace, you can delve into
                the heart of Vijayawada, uncovering hidden gems and weaving your
                unique travel narrative. SR Car Rentals becomes your key to
                unlocking the city's secrets. Explore the majestic Indrakeeladri
                Caves, echoing with whispers of history. Wander through the
                aromatic bylanes of One Town. Seek blessings at the revered
                Kanaka Durga Temple, its golden gopuram shimmering in the sun.
              </p>
              <p className="fs-6">
                So buckle up, <a href="https://srcartravels.in/">get ready</a> to explore, and let SR Car Rentals
                Vijayawada guide you to an unforgettable experience in this
                captivating city.
              </p>
            </div>
          </div>
        </div>
      </div>


      <h2 className="text-center mb-4 fw-bold fs-3">
      What Do We Offer?
      </h2>
      <div className="container">
        {/* Outstation Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt1}
              alt="cab services in vijayawada: outstation"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Driver-Led Rentals at SR Car</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            At SR Car Rentals, we offer various vehicles, including sedans,
SUVs, and luxury cars. We have various chauffeur-driven
options to cater to your travel preferences in Vijayawada.
Experience the flexibility of a chauffeur-driven ride for a more
relaxed travel experience.  
            </p> 
          </div>
        </div>

        <hr style={thinLineStyle} />{" "}
        {/* Thin line after Outstation Services Section */}
        {/* Local Cab Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt2}
              alt="Local Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Tour Packages at Vijayawada</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Discover the <a href="https://srcartravels.in/vijayawada-city-tour">best of Vijayawada</a> and its surrounding
attractions with our tailored city tours and custom travel
packages. Whether you're planning a family trip, a corporate
outing, or a group tour, we offer personalized experiences to
suit your needs. Explore pilgrimage packages to renowned
temples and religious sites in and around Vijayawada,
ensuring a memorable and enriching journey. 
            </p>
          </div>
        </div>
        <hr style={thinLineStyle} />{" "}

        {/* Thin line after Local Cab Services Section */}
        {/* Airport Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt3}
              alt="Airport Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Airport Transfers</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            SR Car Rentals provides convenient pick-up and drop-off
services to and from Vijayawada Airport. Our priority is to offer
timely and reliable transportation, ensuring you reach your
destination without hassle. 
            </p>
          </div>
        </div>


        <hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt4}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">Corporate Travels</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Experience our dedicated services tailored for business
travelers. Benefit from long-term car rental contracts
specifically designed for corporate clients. Take advantage of
our special packages created to meet the transportation
needs of business meetings, conferences, and events
    </p>
  </div>
</div>


<hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt5}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">Outstation Trips</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Our car rentals Vijayawada services offer convenient
outstation travel with multi-day packages to <a href="https://srcartravels.in/outstation">explore various
tourist destinations</a> in Andhra Pradesh and neighboring states.
We ensure well-maintained vehicles for comfortable longdistance journeys, providing a professional and reliable travel
experience. 
    </p>
  </div>
</div>


      </div>


      <hr style={thinLineStyle} />{" "}


      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
        <div className="row align-items-center">
          <h3 className="text-center py-3 fs-3 fw-bold">
            Escape the City: Captivating Road Trip Ideas Near 
            Vijayawada
          </h3>
          <div className="col-md-7 my-2 p-5">
            <ul className="fs-6">
              <li>
                <b>Vijayawada to Amaravati:</b> Enjoy breathtaking views of the
                Krishna River and the lush green fields along the way.
              </li>
              <li>
                <b>Vijayawada to Kondapalli:</b> Experience beautiful views of
                the surrounding hills and enjoy panoramic views of the
                countryside.
              </li>
              <li>
                <b>Vijayawada to Hamsaladeevi Beach:</b> Travel through serene
                coastal villages and take in the breathtaking views of the Bay
                of Bengal.
              </li>
              <li>
                <b>Vijayawada to Nagarjuna Sagar:</b> Renowned for its
                impressive dam and scenic beauty.
              </li>
            </ul>
          </div>

          <div className="col-md-5 ">
            <LazyLoadImage
              src={imagePaths.tileImage1}
              alt="Vijayawada Road Trip Ideas"
              effect="blur"
              width="100%"
              height="auto"
              style={{ borderRadius: "25px" }}
              className="img-fluid"
            />
          </div>
        </div>

        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.tileImage2}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                  Save Big on Your Vijayawada Trip: Budget 
                  Friendly SR Car Rental Options
                </h3>
                <p className="fs-6">
                  Planning a trip to Vijayawada and want to make the most of
                  your budget? Look no further. At SR Car Rentals Vijayawada, we
                  offer a range of budget-friendly car rental options, allowing
                  you to explore the city without breaking the bank.
                </p>

                <p className="fs-6">
                  We understand the significance of sticking to your travel
                  budget. That's why we provide <a href="https://srcartravels.in/sp-offers">competitive pricing</a> on all our
                  car rentals, ensuring that you receive the best value for your
                  money. With SR Car Travels, you can enjoy your trip without
                  worrying about high transportation costs
                </p>

                <p className="fs-6">
                  Travel with peace of mind, knowing that all our vehicles are
                  well-maintained and undergo regular inspections. We prioritize
                  cleanliness, safety, and reliability in every car, enhancing
                  your overall travel experience
                </p>

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a className="btn btn-info text-white bg-dark ">Know More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-3 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InsideGuideInNavigatingVijayawada;
