import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RentACarCsv = () => {
    const imagePath = require("../assets/img/csv_img_3.png"); // Ensure the path is correct

    return (
        <div className="container py-3">
            <div className="row align-items-center">
                <div className="col-lg-4">
                    <LazyLoadImage
                        alt="Rent a Car in Guntur"
                        src={imagePath}
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ width: '350px', height: '330px' }}
                    />
                </div>
                <div className="col-lg-8 ps-lg-3"> {/* Adjusted for content alignment and added padding */}
                    <h2 className='fs-bold fs-5'>Book Your Comfort Ride on Our Website</h2> {/* Changed to H2 as requested */}
                    <p className='fs-6'>To book cabs with SR Car Travels in Vijayawada, you have to follow:</p>
                    <ol>
                        <li>Visit the <a href="https://srcartravels.in/">website</a> of SR Car Travels.</li>
                        <li>Fill in the pickup and destination city with your mobile number and date of journey.</li>
                        <li>Following these steps, you can access various car rental packages, allowing you to select your preferred cab.</li>
                        <li>To secure your ride, you can make an advance payment of 500 rupees.</li>
                        <li>Once you provide all the necessary ride details, you can choose your payment method and proceed with the transaction.</li>
                    </ol>
                    <div className="d-flex flex-column flex-sm-row align-items-center gap-2 mt-3">
                        <Button variant="outline-primary" href="tel:+918886556632">
                            <i className="bi bi-telephone-fill"></i> Call Now: +91-8886556632
                        </Button>
                        <Button variant="outline-primary" href="mailto:contact@srcartravels.in">
                            <i className="bi bi-envelope-fill"></i> Email Us
                        </Button>
                    </div>
                    <a
                        href="https://srcartravels.in/"
                        className="mt-3 btn btn-primary"
                        variant="primary"
                        style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                    >
                        Know More
                    </a>
                </div>
            </div>
        </div>
    );
};

export default RentACarCsv;
