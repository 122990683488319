import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';
import "./KeyWordStyle.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
    bannerImage: require("../assets/img/BannerCabsVijayawadatoHyderabad.png"),
    via1:require("../assets/img/lp3/viaweb.png"),
    via2:require("../assets/img/lp3/viaphone.png"),
    via3:require("../assets/img/lp3/viamail.png"),
    lp1: require("../assets/img/lp4/lp-1.png"),
    lpn: require("../assets/img/lp4/lp-n.png"),
    lp2: require("../assets/img/lp4/lp-2.png"), 
    lpt1: require("../assets/img/lp4/ic_1.png"),
    lpt2: require("../assets/img/lp4/ic_2.png"),
    lpt3: require("../assets/img/lp4/ic_3.png"),
    lpt4: require("../assets/img/lp4/ic_4.png"), 
    lpt5: require("../assets/img/lp4/ic_5.png"), 
    lpt6: require("../assets/img/lp4/ic_6.png"), 
    card1: require("../assets/img/lp4/angle1.png"), 
    card2: require("../assets/img/lp4/angle1.png"), 
    card3: require("../assets/img/lp4/angle2.png"),   
    card4: require("../assets/img/lp4/angle3.png"),   
    card5: require("../assets/img/lp4/angle4.png"),    
  };
  
  
  // Custom style for the thin line
  const thinLineStyle = {
    width: "75%",
    margin: "20px auto", // Combined marginTop and marginBottom
    height: "1px",
    backgroundColor: "#ddd",
  };
  
  const data = [
    {
      title:
        "What types of vehicles are available for outstation trips from Vijayawada to Hyderabad?",
      content:
        "We provide an extensive selection of vehicles to meet your diverse needs, including sleek sedans, versatile SUVs, and luxury cars. Whether you're looking for comfort on solo journeys or ample space for larger groups, our fleet is curated to accommodate your specific preferences and ensure a seamless travel experience",
    },
    {
      title: "How can I book a cab for an outstation trip?",
      content:
        "You may conveniently book your cab through our website or by contacting our team at +91 8886556632",
    },
    {
      title: "Can I make multiple stops during the journey?",
      content:
        "Yes, you may request multiple stops during your trip. Kindly inform our team in advance so that we can plan the most efficient route and schedule for you.",
    },
    {
      title: "How do I know my booking is confirmed?",
      content:
        " Once your reservation is confirmed, you will receive a confirmation message containing your booking details via email or SMS.",
    },
    {
      title:
        "How long does the journey from Vijayawada to Hyderabad typically take?",
      content:
        "The journey duration typically ranges from 5 6 hours, depending on traffic conditions and the selected route.",
    },
  ];
  
  const bookingOptions = [
    {
      imgSrc: imagePaths.card1,
      title: "Plan Ahead",
      text: (
        <>
        To ensure a smooth and stress free travel
        experience, it is highly recommended that you
        book your transportation and accommodation
        well in advance. You can book SR cabs
        Vijayawada from Hyderabad on our website
        seamlessly
        </>
      ),
    },
    {
      imgSrc: imagePaths.card2,
      title: "Stay Connected",
      text: (
        <span>
          Before beginning your journey, ensure your
          mobile phone is fully charged & bring a portable
          charger to avoid interruptions. For added safety,
          share your travel itinerary with family or friends.
          With SR Car Travels, there's no need for concern.
          Leave the logistics to us and simply relish the
          ride. 
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card3,
      title: "Adequate Cash and Payments",
      text: (
        <span>
            When traveling, ensure you have sufficient cash
            for minor purchases and potential emergencies.
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card4,
      title: "Travel Comfort",
      text: (
        <span>
            We recommend selecting attire and footwear
            that provide ease and support for optimal
            comfort during extended journeys. Additionally,
            incorporating neck pillows and blankets can
            significantly enhance your travel experience.
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card5,
      title: "Emergency Contacts",
      text: (
        <span>
          Maintain a list of critical emergency contacts,
          including the cab service provider, like SR Car
          Travels authorities.
        </span>
      ),
    } 
  ];

const CabsVijayawadaToHyderabad = ({BannerTitle}) => {
    const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

    const handleToggle = (index) => {
      setActiveKeys(
        (prevKeys) =>
          prevKeys.includes(index)
            ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
            : [...prevKeys, index], // Open the accordion if it's closed
      );
    };
  
    const toggleIcon = (index) => {
      return activeKeys.includes(index) ? (
        <i className="fa fa-minus" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-plus" aria-hidden="true"></i>
      );
    };

    return (
        <>
            <Helmet>
                <title> Cabs Vijayawada to Hyderabad - SR Car Travels</title>
                <meta name="description" content="Discover the freedom of exploring beyond city limits with SR Car Travels' reliable outstation cabs  Vijayawada to Hyderabad rides at unbeatable fares." />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/cabs-vijayawada-to-hyderabad" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content=" Cabs Vijayawada to Hyderabad - SR Car Travels" />
                <meta property="og:description" content="Discover the freedom of exploring beyond city limits with SR Car Travels' reliable outstation cabs  Vijayawada to Hyderabad rides at unbeatable fares." />
            </Helmet> 

            <div className="banner-contents-container">
            <img
            src={imagePaths.bannerImage}
            className="banner-backdrop d-block w-100"
            loading="lazy"
            alt="Banner"
            />
            <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
            </h1>
            </div>

 
            <div className="container-fluid">
            <div className="container">
            <div className="row align-items-center">
            <div className="col-md-5">
            <LazyLoadImage
            src={imagePaths.lp1}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid"
            />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
            <h2 class="fw-bold pb-3">
            Effortless Travel Between Vijayawada & Hyderabad with SR Car Travels
            </h2>
            <p className="fs-6">
            Welcome to <a href="https://srcartravels.in/" className="text-dark">SR Car Travels</a>, your trusted partner for seamless and reliable transportation services. Whether you're planning a business trip, visiting family, or exploring the vibrant cityscapes between Vijayawada and Hyderabad, we’re here to ensure that your journey is nothing short of exceptional.
            </p>
            <p className="fs-6">
            At SR Cabs Vijayawada to Hyderabad, we offer fair prices and a selection of the best offers tailored just for you. Our verified drivers are meticulously chosen based on their ratings, reviews, and vehicles, guaranteeing safety and comfort throughout your travel experience. 
            </p> 
            </div>
            </div>
            </div>
            </div> 





        <div
        class="container-fluid px-5 my-5"
        style={{
        backgroundColor: "#113042",
        color: "#fff",
        paddingTop: "60px",
        paddingBottom: "60px",
        }}
        >
        <div className="row align-items-center">
        <div className="col-md-7 my-2 p-5">
        <h3 className="text-left py-3 fs-3 fw-bold">
        Outstation rides at Affordable Fares with SR Car Travels.
        </h3>
        <p>
        Discover the freedom of exploring beyond city limits with SR Car Travels' reliable
        outstation cabs  Vijayawada to Hyderabad rides at unbeatable fares.SR Car Travels
        offers superior car travel options at an affordable price range. Our carefully maintained
        fleet ensures you enjoy comfort and safety on every journey. With transparent pricing
        and zero hidden fees, you can focus on your tour without worrying about unexpected
        costs. Trust our experienced drivers to skillfully navigate the roads while you relax and
        soak in the scenic views.
        </p>
        <p>
        Choose SR Car Travels for your outstation journeys and experience premium service
        tailored to meet your needs—all at exceptional prices.  
        </p>
        </div>

        <div className="col-md-5 ">
        <LazyLoadImage
        src={imagePaths.lp2}
        alt="Vijayawada Road Trip Ideas"
        effect="blur"
        width="100%"
        height="auto"
        style={{ borderRadius: "25px" }}
        className="img-fluid"
        />
        </div>
        </div>
        </div>




 

        <h2 className="text-center mb-4 fw-bold fs-3 mt-5">
        What Perks You Get When You Hire SR Car<br/> Travels Services?
            </h2>
            <div className="container mt-5">
            {/* Outstation Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt1}
            alt="cab services in vijayawada: outstation"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Comfortable & WellMaintained Cabs</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Experience unparalleled comfort and sophistication with our
            meticulously maintained fleet of contemporary vehicles. We
            ensure every journey is smooth, pleasant, and reflective of the
            highest standards in travel. <br/>
            Explore our fleet of cars/cabs <a href="https://srcartravels.in/" className="text-dark">here</a>
            </p> 
            </div>
            </div>

            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Outstation Services Section */}
            {/* Local Cab Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt2}
            alt="Local Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Experienced & Courteous
            Drivers</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Our professional and courteous drivers have extensive
            knowledge of the routes from  <a href="https://srcartravels.in/taxi-from-vijayawada-to-hyderabad" className="text-dark">Vijayawada to Hyderabad</a>,
            guaranteeing every passenger a safe and timely journey.
            </p>
            </div>
            </div>
            <hr style={thinLineStyle} />{" "}

            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt3}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Flexible Scheduling of Tours</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Select from a range of pickup and drop off times tailored to
            your schedule, ensuring optimal convenience and flexibility.
            </p>
            </div>
            </div>
 
            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt4}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">On-time pick-up & Drop
            Service</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Enjoy the ease of our door to door pickup and drop off
            services, removing the burden of arranging extra
            transportation.
            </p>
            </div>
            </div>


 
            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt5}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Comfort Stops</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Make the most of our thoughtfully scheduled comfort stops,
            allowing you to enjoy refreshments and take necessary breaks.
            This ensures your comfort is maintained throughout the entire
            journey.
            </p>
            </div>
            </div>




            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt6}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Customer Support</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Benefit from our round the clock customer support, available
            round the clock to address any inquiries/ issues you may
            encounter throughout your journey.
            </p>
            </div>
            </div>





            </div>





            <div className="container">
        <h2 className="my-5 text-center fs-3 fw-bold">
        Travel Tips and Essential Information For <br/>Outstation Tours
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {bookingOptions.map((option, index) => (
            <div key={index} className="col">
              <div className="card h-100 " style={{borderRadius: '30px'}}>
                <LazyLoadImage
                  src={option.imgSrc}  
                  alt={option.title}
                  effect="blur"
                  className="py-4 px-3"
                />
                <div className="card-body text-left">
                  <h3 className="card-title fs-5">{option.title}</h3>
                  <p className="card-text fs-6">{option.text}</p>
                </div>
              </div>
              
            </div>
            
          ))}

            <div   className="col " >
                <div className="card h-100  align-middle"  style={{ padding: '25% 4%',borderRadius: '30px',textAlign:'center' }}>
         
                <div className="card-body">
                    <h3 className="card-title fs-6">Learn More About The Effortless
                    Sightseeing In Vijayawada</h3>
                    <a
                    className="btn btn-info text-white bg-dark my-3 "
                    style={{ borderRadius: "20px", border: "none" }}
                    >
                    Learn More
                  </a>
                </div>
                </div>
                
            </div>


        </div> 
      </div>




 


            {/* Thin line after Outstation Services Section */}
            <div className="container pt-5">
            <div className="container-fluid">
            <div className="container ">
            <div className="row align-items-center ">
            {/* Image column */}
            <div className="col-md-5 ">
            <LazyLoadImage
            src={imagePaths.lpn}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid "
            />
            </div>

            {/* Content column */}
            <div className="col-md-7 my-2 py-5 ps-5">
            <h3 className="fs-3 fw-bold">
            Seamless Travel Solutions: Vijayawada Car Travels - Your Reliable Partner
            </h3>
            <p className="fs-6">
            At SR Car Travels,  we offer premier cab outstation services from Vijayawada to Hyderabad. Our commitment is to provide reliable, affordable, and safe transportation options for our valued travelers. We take great pride in the extensive range of professional services we deliver. Our top notch services include airport transfers between the cities, sightseeing in Vijayawada and Hyderabad, and outstation services. 
            </p>

            <p className="fs-6">
            SR Cabs' Vijayawada to Hyderabad services offer transparent pricing and dedicated customer service, promising a hassle free experience from beginning to end. Choose us for your next outstation journey from Vijayawada, and relish the peace of mind that comes with our dependable car rental service. 
            </p>
 

            <div class="d-flex align-items-center ">
            <a href="tel:+918886556632" class="me-3 text-dark">
            <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
            </a>
            <a href="mailto:contact@srcartravels.in" class="text-dark">
            <i class="bi bi-envelope-fill"></i>
            &nbsp;contact@srcartravels.in
            </a>
            </div>

            <br />
            <div>
            <a
            className="btn btn-info text-white bg-dark "
            style={{ borderRadius: "20px", border: "none" }}
            >
            Know More
            </a>
            </div>
            </div>
            </div>
            </div>
            </div>

            <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

            {/* Accordion Section */}
            <div className="accordion px-5 mx-5">
            {data.map((item, index) => (
            <div key={index} class="py-2">
            <button
            className="accordion-button fs-4 text-dark"
            type="button"
            onClick={() => handleToggle(index)}
            aria-expanded={activeKeys.includes(index) ? "true" : "false"}
            aria-controls={`collapse${index}`}
            >
            <span className="accordion-title">{item.title}</span>
            <span className="accordion-icon">{toggleIcon(index)}</span>
            </button>
            {activeKeys.includes(index) && (
            <div className="accordion-content">{item.content}</div>
            )}
            </div>
            ))}
            </div>
            </div>
        </>
    );
};

export default CabsVijayawadaToHyderabad;
