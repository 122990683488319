import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Adjust the image paths according to your project structure
const imagePaths = {
    luxuryAndComfort: require("../assets/img/csv_img_5.png"),
    extensiveCoverage: require("../assets/img/csv_img_6.png"),
    trustedReputation: require("../assets/img/csv_img_7.png"),
    transparentPricing: require("../assets/img/csv_img_8.png")
};

const featureDescriptions = {
    luxuryAndComfort: (
        <>
            <p>We provide an exquisite fleet of top-tier vehicles that redefine the luxury travel experience.</p>
            <p>When you choose to travel with us, your journey becomes an extraordinary experience where comfort and style converge to guarantee a truly remarkable ride.</p>
        </>
    ),
    extensiveCoverage: (
        <>
            <p>For any journey within the city, to the airport, or on an outstation adventure, rest assured, knowing that <a href='https://srcartravels.in/vijayawada-city-tour'>our services</a> encompass a broad spectrum of destinations to fulfill your travel requirements.</p>
        </>
    ),
    trustedReputation: (
        <>
            <p>SR Car Travels is a distinguished brand known for providing top-notch transportation services. When you opt for our services, you are choosing a reputable and esteemed company that consistently upholds high standards of excellence.</p>
        </>
    ),
    transparentPricing: (
        <>
            <p>With SR Car Travels, you can trust our commitment to transparency and clarity. Our pricing policy guarantees you'll always be aware of the exact fare upfront, empowering you to plan your budget confidently without any surprises.</p>
        </>
    )
};

const UniqueFeatures = () => {
    return (
        <div className="container py-5">
            <h2 className="text-center mb-4 fw-bold fs-3">What Makes Us Different From Other Cab Services in Vijayawada?</h2>
            {Object.entries(imagePaths).map(([key, imagePath], index) => (
                <div className="row align-items-center mb-5" key={key}> {/* Use row and align-items-center to align content */}
                    <div className={`col-md-4 ${index % 2 !== 0 ? 'order-md-2' : ''}`}> {/* Conditionally switch order for alternate rows */}
                        <LazyLoadImage
                            src={imagePath}
                            alt={key}
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    <div className={`col-md-8 ${index % 2 !== 0 ? 'order-md-1' : ''}`}> {/* Text takes the remaining space */}
                        <h3 className="fw-bold"  style={{textTransform: 'capitalize' }}>{key.replace(/([A-Z])/g, ' $1').trim()}</h3>
                        {typeof featureDescriptions[key] === 'string' ? (
                            <p>{featureDescriptions[key]}</p>
                        ) : (
                            featureDescriptions[key]
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UniqueFeatures;
