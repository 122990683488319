import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const VijayawadaCarRentalsExperience = () => {
    // Update the path as per your project structure
    //const imagePath = require("../assets/img/g_frame_1.png");

    const imagePaths = {
        carRentals : require("../assets/img/sr_travels.png"),
        doorStepPickup : require("../assets/img/ic_1.png"),
        quickEasyBooking : require("../assets/img/ic_2.png"),
        topCarBrands : require("../assets/img/ic_3.png"),
    }
    return (
    <div className="container-fluid py-5">

  <div className="container ">  
    <h2 className="text-center mb-4">
        <b>Why Choose SR Car Travels ?</b>
    </h2>

    <div className="container my-5">
      <div className="row">
        {/* Review 1 */}
        <div className="col-lg-4 col-md-6 mb-1">
          <div className="card border-0">
              <LazyLoadImage
                  src={imagePaths.doorStepPickup}
                  alt="Explore Vijayawada with SR Car Travels"
                  effect="blur"
                  className="img-fluid rounded"
              />
            <div className="card-header bg-white border-bottom-0 px-0 mt-3">
              <h5 className="mb-0">Why Choose SR Car Travels ?</h5> 
            </div>
            <div className="card-body px-0">
              <p className="card-text">
              At SR Car Travels in Vijayawada, we provide a
              convenient doorstep pick-up and drop-off
              service, ensuring a hassle-free travel experience.
              Our reliable service ensures that you are picked
              up from your location and safely transported to
              your desired destination, delivering both comfort
              and efficiency. With SR Car Travels, you can enjoy
              seamless, stress-free journeys every time. 
              </p><br />
            </div>
          </div>
        </div>

        {/* Review 2 */}
        <div className="col-lg-4 col-md-6 mb-1">
          <div className="card border-0">
              <LazyLoadImage
                  src={imagePaths.quickEasyBooking}
                  alt="Explore Vijayawada with SR Car Travels"
                  effect="blur"
                  className="img-fluid rounded"
              />
            <div className="card-header bg-white border-bottom-0 px-0 mt-3">
              <h5 className="mb-0">Quick & Easy Car Booking</h5> 
            </div>
            <div className="card-body px-0">
              <p className="card-text">
              Booking a car with SR <a href="https://srcartravels.in/car-travels-vijayawada">Car Travels Vijayawada</a> couldn't be simpler. Our website enables you to
              secure a vehicle in just a few clicks, guaranteeing
              a swift and efficient process. Benefit from swift
              confirmations and a stress-free booking
              experience, ensuring your travel arrangements
              are seamless and convenient.
              </p>
            </div>
          </div>
        </div>

        {/* Review 3 */}
        <div className="col-lg-4 col-md-6 mb-1">
          <div className="card border-0">
            <LazyLoadImage
                  src={imagePaths.topCarBrands}
                  alt="Explore Vijayawada with SR Car Travels"
                  effect="blur"
                  className="img-fluid rounded"
            />
            <div className="card-header bg-white border-bottom-0 px-0 mt-3">
              <h5 className="mb-0">Top Car Brands & Models </h5> 
            </div>
            <div className="card-body px-0">
              <p className="card-text">
              SR Car Travels in Vijayawada provides a diverse
              fleet comprising top car brands and models.
              Select from a range of luxury options, including
              SUVs and Innova, as well as reliable favourites
              such as Swift Dzire, Etios, and Crysta. Whether for
              business or leisure, our premium selection
              guarantees a comfortable and stylish journey for
              every occasion. 
              </p><br />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
      <a href="tel:8886556632" className="btn btn-info text-white" >Reach out to us</a> 
      </div>
    </div>
    
  </div>


 

      <div className="row align-items-center bg-light p-5">

          {/* Content column */}
          <div className="col-md-6 my-2 px-md-5">
          <h3>SR Car Travels in Vijayawada</h3>
          <p className="fs-6">
          Discover comfort and reliability with SR Car Travels, your ultimate
          destination for car rentals in Vijayawada. Choose from our diverse
          fleet of meticulously maintained vehicles, including city-friendly
          hatchbacks and spacious SUVs perfect for outstation getaways.
          </p>
          
          <p className="fs-6">
          Count on our amiable and seasoned drivers to ensure a seamless
          and delightful journey, whether you're navigating the bustling city
          or exploring distant locales. With offerings such as airport pick-ups,
          city tours, and comprehensive outstation packages, we cater to all
          your travel necessities.
          </p>

          <p className="fs-6">
          Embark on a stress-free travel experience in Vijayawada by booking
          your car with SR Car Travels today. Our commitment to customer
          satisfaction guarantees a remarkable difference in your travels
          </p>
      
          </div>

          {/* Image column */}
          <div className="col-md-6 px-md-5 px-3">
              <LazyLoadImage
                  src={imagePaths.carRentals}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
              />
          </div>
      </div>


      </div>
    );
};

export default VijayawadaCarRentalsExperience;
