import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import "../LandingPages/InsideGuideInNavigatingVijayawada.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
  bannerImage: require("../assets/img/lp1/l1.png"),
  lp1: require("../assets/img/lp01.png"),
  lp2: require("../assets/img/lp02.png"),
  lp3: require("../assets/img/lp03.png"),
  lp4: require("../assets/img/lp04.png"),
  lpt1: require("../assets/img/lpt2-1.png"),
  lpt2: require("../assets/img/lpt2-2.png"),
  lpt3: require("../assets/img/lpt2-3.png"),
  lpt4: require("../assets/img/lpt2-4.png"),  
};

// Custom style for the thin line
const thinLineStyle = {
  width: "75%",
  margin: "20px auto", // Combined marginTop and marginBottom
  height: "1px",
  backgroundColor: "#ddd",
};

const data = [
  {
    title: "What happens if the vehicle breaks down during the trip ?",
    content: "We offer round the-clock roadside assistance to manage emergencies, guaranteeing minimal disruption to your journey effectively.",
  },
  {
    title: "Are the vehicles insured?",
    content: "All our vehicles are fully insured. It s advisable to review the insurance coverage details at the time of booking to ensure you clearly understand the coverage provided.",
  },
  {
    title: "Can I cancel or modify my outstation booking?",
    content: "Yes, you can cancel/modify your booking. For specific terms & conditions, please refer to our cancellation policy on the website.",
  },
  {
    title: "Are there any mileage limits for outstation trips?",
    content: "Our rental packages come with specific mileage limits in place. If you exceed the allotted mileage, please be aware that additional charges may apply. We recommend checking your rental agreement for more detailed information on this matter",
  },
  {
    title: "What destinations can I travel to with SR Car Travels' outstation services?",
    content: " From Vijayawada, you can explore a range of popular destinations, such as Amaravati, Kondapalli, Hamsaladeevi Beach, Nagarjuna Sagar, and Srisailam. We specialize in creating custom routes tailored to your specific travel requirements.",
  }
  
];

const InsideGuideInNavigatingVijayawada = ({BannerTitle}) => {
  const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

  const handleToggle = (index) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(index)
          ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
          : [...prevKeys, index], // Open the accordion if it's closed
    );
  };

  const toggleIcon = (index) => {
    return activeKeys.includes(index) ? (
      <i className="fa fa-minus" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-plus" aria-hidden="true"></i>
    );
  };

  return (
    <>
      <Helmet>
        <title>Cars for Rent Vijayawada | SR Car Travels</title>
        <meta
          name="description"
          content="Discover stress-free, memorable outstation travel with SR Cars for Rent Vijayawada. Enjoy reliable, flexible car rentals and a diverse fleet of well-maintained vehicles."
        />
        <link
          rel="canonical"
          href="https://srcartravels.in/cars-for-rent-vijayawada"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta
          property="og:title"
          content="Cars for Rent Vijayawada | SR Car Travels"
        />
        <meta
          property="og:description"
          content="Discover stress-free, memorable outstation travel with SR Cars for Rent Vijayawada. Enjoy reliable, flexible car rentals and a diverse fleet of well-maintained vehicles."
        />
      </Helmet>
      <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
        <h1 className="mb-3 text-center banner-title-h1">
        {BannerTitle} 
        </h1>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.lp1}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
              <h2>
              Experience Comfort With SR Cars For
              Rent Vijayawada Outstation Services
              </h2>
              <p className="fs-6 ">
              Welcome to the world of pristine journeys beyond city limits with SR Cars for Rent Vijayawada
<a href="https://srcartravels.in/outstation">Outstation Services</a>. Picture yourself gazing out at breathtaking landscapes, ancient temples
whispering stories of the past, or serene beaches motioning with the promise of relaxation. At SR
Cars for rent Vijayawada, we're not just about car rentals; we're about unlocking the potential for
truly exceptional outstation experiences in Vijayawada and beyond.
              </p>
              <p className="fs-6">
              So, buckle up, get ready to explore, and let SR Cars for Rent be your guide to memorable journeys.
              </p>
             
            </div>
          </div>
        </div>
      </div>
   
       <div class="container-fluid px-5 my-5"    style={{ backgroundColor: '#113042',color:'#fff',  paddingTop: '60px', paddingBottom: '60px' }} >

       <div className="row align-items-center">
         
          <div className="col-md-7 my-2 p-5">
          <h3 className="text-left py-3 fs-3 fw-bold">
          Find Your Perfect Ride: Explore SR Cars' Diverse
          Rental Fleet
          </h3>
     
          <p className="fs-6 ">
          Experience your perfect next journey with SR Car Travels' diverse rental fleet. Whether
you're embarking on a solo business trip, a family vacation, or a group outing, our wide
range of well-maintained cars is tailored to meet your specific needs.
              </p>

              <p className="fs-6 ">
              Select from our economy cars for budget-friendly travel, sedans for a comfortable and
smooth ride, SUVs for spacious and rugged adventures, or luxury cars for special
occasions when you want to travel in style. Each vehicle boasts modern amenities and
undergoes regular servicing to ensure a safe and enjoyable ride. At SR Car Travels, you'll
discover the ideal car to match your travel requirements and indulge in a seamless
rental experience
              </p>

          </div>

          <div className="col-md-5 ">
            <LazyLoadImage
              src={imagePaths.lp2}
              alt="Vijayawada Road Trip Ideas"
              effect="blur"
              width="100%"
              height="auto"
              style={{ borderRadius: "25px" }}
              className="img-fluid"
            />
          </div>
        </div>



       </div>





       <div class="container p-5">
       <div className="row align-items-center">
          <h3 className="text-center py-3 fs-3 fw-bold">
        Our Collection
          </h3>
         
          <div className="col-md-12 px-5">
 

          <table class="table table-bordered">
  
  <tbody>
    <tr>
      <th scope="row">Taxi Type</th>
      <td>Sedan (Dezire, Etios)</td>
      <td>SUV Ertiga</td>
      <td>Innova</td>
    </tr>
    <tr>
      <th scope="row">Capacity</th>
      <td>4+1</td>
      <td>6+1</td>
      <td>7+1</td>
    </tr> 
  </tbody>
</table>

          </div>
        </div>
        </div>






       <div class="container">
       <div className="row align-items-center">
          <h3 className="text-center py-3 fs-3 fw-bold">
          Stress-Free Travel Starts Here: Easy Booking with<br/>
          SR Car Rentals
          </h3>
        

          <div className="col-md-5 ">
            <LazyLoadImage
              src={imagePaths.lp3}
              alt="Vijayawada Road Trip Ideas"
              effect="blur"
              width="100%"
              height="auto"
              style={{ borderRadius: "25px" }}
              className="img-fluid"
            />
          </div>


          <div className="col-md-7 my-2 p-5">
          <p>Our efficient and intuitive online booking system enables you to effortlessly reserve a vehicle in just
          a few clicks, guaranteeing that you secure the car you require without any hassle</p>
            <ul className="fs-6">
            
              <li>
                <b> Online Booking: </b>asily reserve your car through our user-friendly <a href="https://srcartravels.in/login">website</a> with a few clicks
              </li>
              <li>
                <b>Phone Reservations:</b> Reach out to our dedicated customer support team for seamless trip
                booking or any assistance you may need.
              </li>
              
            </ul>
          </div>
        </div>
        </div>




      <h2 className="text-center mb-4 fw-bold fs-3 py-5">
      Why Choose SR Car Travels for Outstation<br/>
Services?
      </h2>
      <div className="container">
        {/* Outstation Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt1}
              alt="cab services in vijayawada: outstation"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Experienced Drivers</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Every driver undergoes meticulous selection and training to
uphold the highest safety, professionalism, and customer care
standards. Their extensive knowledge of local routes and
tourist attractions enables them not only to ensure a smooth
and efficient journey but also to enhance your travel
experience with valuable insights and recommendations
            </p> 
          </div>
        </div>

        <hr style={thinLineStyle} />{" "}
        {/* Thin line after Outstation Services Section */}
        {/* Local Cab Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt2}
              alt="Local Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">24/7 Customer Support
            </h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            At SR Cars for rent Vijayawada, we provide round the-clock
customer support to guarantee a seamless and stress-free
travel experience. Our team is readily available to assist with
bookings, address any inquiries, and offer emergency
assistance whenever required. Whether it s day or night, you
can rely on us for dependable and prompt service.
            </p>
          </div>
        </div>
        <hr style={thinLineStyle} />{" "}

        {/* Thin line after Local Cab Services Section */}
        {/* Airport Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt3}
              alt="Airport Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Flexible and Affordable Plans</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            With our flexible rental plans, we cater to your unique travel
needs and budget. Whether you require a car for a few hours, a
day, or an extended period, we offer competitive rates with
<a href="https://srcartravels.in/login">transparent pricing</a>. Benefit from special discounts on long
term rentals and custom packages tailored to your specific
requirements. At SR Car Travels, we prioritize allowing you to
travel on your terms without compromising affordability.
            </p>
          </div>
        </div>


        <hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt4}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">Pick up and Drop Off Services</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    We prioritize ensuring that your journey begins and ends
seamlessly with our convenient pick-up and drop-off services.
Our reliable service guarantees punctual and stress-free travel
from airport arrivals to transportation from your home to any
railway station. Our team of professional drivers will assist you
with your luggage & ensure a comfortable ride to your
destination. 
    </p>
  </div>
</div>


 


      </div>


      <hr style={thinLineStyle} />{" "}


      <div className="container pt-5 text-center px-5 ">
            <div className="row px-5">
            <p class="text-center">
            With flexible scheduling and a steadfast commitment to punctuality, SR Car Travels simplifies the process of traveling to and from
            your location, allowing you to focus on fully enjoying your trip.
            </p>
            <div>
                  <a className="btn btn-info text-white bg-dark " style={{ borderRadius : '20px', border: 'none' }}>Explore our services here
                  </a>
                </div>
                </div>
      </div>




      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
 

        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.lp4}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                Escape the Ordinary: Unforgettable Outstation
                Travel Starts with SR Cars
                </h3>
                <p className="fs-6">
                At SR Cars for Rent Vijayawada, we aim to elevate your outstation travel experiences by providing
reliable and flexible car rental services. With a diverse fleet of well-maintained vehicles, easy
booking options, experienced drivers, 24/7 customer support, and flexible pricing plans, we ensure
your journeys are stress-free and memorable.
                </p>

                <p className="fs-6">
                Whether you're seeking a budget-friendly economy car or a luxurious ride for special occasions, SR
Cars has the perfect vehicle tailored to your needs. Our commitment to safety, punctuality, and
customer satisfaction makes us the ideal choice for your travel requirements in Vijayawada and
beyond.
                </p>

                <p className="fs-6">
                So, when you're ready to embark on exceptional journeys with peace of mind and convenience, trust
SR Cars for Rent Vijayawada to be your guide. Let s make every trip a remarkable experience.
                </p>

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a className="btn btn-info text-white bg-dark "  style={{ borderRadius : '20px', border: 'none' }}>Know More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-3 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InsideGuideInNavigatingVijayawada;
