import React from "react";
import { Carousel, Card } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'; // Import react-responsive

const imagePaths = {
    custProfImage1: require("../../assets/img/reviewprofile1.png"),
    custProfImage2: require("../../assets/img/reviewprofile2.png"),
    // Add more images if necessary
};

const SRCustomerReviews = () => {
  // Use media queries to detect screen size
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
   
  const reviews = [
    {
      name: "Ariella Krupa Joy",
      image: imagePaths.custProfImage1,
      review: `"I recently used SR Car Travels for my trip, and I must say, I was extremely impressed with their service. The car was clean and well-maintained, and the driver was professional, courteous, and punctual. The ride was smooth and comfortable, and I reached my destination on time without any hassle. The booking process was also very easy and hassle-free. I highly recommend SR Car Travels to anyone looking for a reliable and comfortable travel experience"`,
      rating: 4
    },
    {
      name: "Akshay Kumar",
      image: imagePaths.custProfImage2,
      review: `"I have been using SR Car Travels for my business trips for the past few months, and I can confidently say that they provide top-notch service. The cars are always clean and well-maintained, and the drivers are very professional and knowledgeable about the routes. They always ensure that I reach my destination on time, and the booking process is very easy and hassle-free. I highly recommend SR Car Travels to anyone looking for a reliable and comfortable travel experience."`,
      rating: 4
    },
    // Add more reviews if necessary
  ];

  const renderStars = (rating) => {
    return (
      <p className="text-left">
        {[...Array(5)].map((star, index) => (
          <span
            key={index}
            className={`fa fa-star ${index < rating ? 'star-active' : 'star-inactive'}`}
            style={{ color: index < rating ? '#F9A825' : '#ddd', cursor: "pointer" }}
          ></span>
        ))}
      </p>
    );
  };

  return (
    <div>
      {/* SEO-Friendly Tags */}
      <title>Customer Reviews - SR Car Travels</title>
      <meta
        name="description"
        content="Read customer reviews for SR Car Travels. Our customers share their experiences and satisfaction with our services."
      />

      <div className="container-fluid py-5 mb-5" style={{ position: "relative" }}>
        <div className="row">
          <div className="col-md-5 p-5">
            <h2 className="ps-5">What Our Customers Say</h2>
          </div>
          <div className="col-md-7" style={{ backgroundColor: "rgb(23, 49, 65)" }}></div>

          <Carousel
            style={{ position: "relative" }}
            controls={true} // Show navigation arrows
            indicators={true} // Show the indicator dots
            interval={null} // Disable auto sliding
            touch={true} // Enable swipe gestures for mobile
          >
            {/* Dynamically render carousel items based on screen size */}
            {isMobile ? (
              // Show one item per carousel on mobile
              reviews.map((review, index) => (
                <Carousel.Item key={index}>
                  <div className="d-flex justify-content-center">
                    <Card className="bg-white px-5 py-3" style={{ maxWidth: '500px' }}>
                      <div className="d-flex">
                        <div className="text-left">
                          <img
                            className="profile-pic"
                            style={{ width: "90px", height: "90px", borderRadius: "100%", marginRight: "30px" }}
                            src={review.image}
                            alt="Customer"
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <h5 className="mt-2 mb-0">{review.name}</h5>
                          <div>{renderStars(review.rating)}</div>
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Text>
                          <small className="text-muted">{review.review}</small>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Carousel.Item>
              ))
            ) : (
              // Show two items per carousel on desktop
              reviews.map((review, index) => (
                index % 2 === 0 && (
                  <Carousel.Item key={index}>
                    <div className="d-flex justify-content-center">
                      {/* First review in the pair */}
                      <Card className="bg-white px-5 py-3 me-4" style={{ maxWidth: '500px' }}>
                        <div className="d-flex">
                          <div className="text-left">
                            <img
                              className="profile-pic"
                              style={{ width: "90px", height: "90px", borderRadius: "100%", marginRight: "30px" }}
                              src={reviews[index].image}
                              alt="Customer"
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <h5 className="mt-2 mb-0">{reviews[index].name}</h5>
                            <div>{renderStars(reviews[index].rating)}</div>
                          </div>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            <small className="text-muted">{reviews[index].review}</small>
                          </Card.Text>
                        </Card.Body>
                      </Card>

                      {/* Second review in the pair, if it exists */}
                      {reviews[index + 1] && (
                        <Card className="bg-white px-5 py-3" style={{ maxWidth: '500px' }}>
                          <div className="d-flex">
                            <div className="text-left">
                              <img
                                className="profile-pic"
                                style={{ width: "90px", height: "90px", borderRadius: "100%", marginRight: "30px" }}
                                src={reviews[index + 1].image}
                                alt="Customer"
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <h5 className="mt-2 mb-0">{reviews[index + 1].name}</h5>
                              <div>{renderStars(reviews[index + 1].rating)}</div>
                            </div>
                          </div>
                          <Card.Body>
                            <Card.Text>
                              <small className="text-muted">{reviews[index + 1].review}</small>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      )}
                    </div>
                  </Carousel.Item>
                )
              ))
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default SRCustomerReviews;
