import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';
import "./KeyWordStyle.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
    bannerImage: require("../assets/img/BannerVijayawadatoHyderabadCabBooking.png"), 
    lp1: require("../assets/img/lp5/lp-1.png"),
    lpn: require("../assets/img/lp5/lp-n.png"),
    lp2: require("../assets/img/lp4/lp-2.png"), 
    lpt1: require("../assets/img/lp5/ic_1.png"),
    lpt2: require("../assets/img/lp5/ic_2.png"),
    lpt3: require("../assets/img/lp5/ic_3.png"),
    lpt4: require("../assets/img/lp5/ic_4.png"),  
    card1: require("../assets/img/lp5/angle1.png"), 
    card2: require("../assets/img/lp5/angle2.png"), 
    card3: require("../assets/img/lp5/angle3.png"),      
  };
  
  
  // Custom style for the thin line
  const thinLineStyle = {
    width: "75%",
    margin: "20px auto", // Combined marginTop and marginBottom
    height: "1px",
    backgroundColor: "#ddd",
  };
  
  const data = [
    {
      title:
        "What types of vehicles are available for the Vijayawada to Hyderabad route?",
      content:
        "We provide many vehicles, including sedans, SUVs, and luxury cars. Our fleet is designed to accommodate both your comfort and budgetary needs.",
    },
    {
      title: "Are the drivers experienced & knowledgeable about the route?",
      content:
        "Yes, all our drivers are highly skilled professionals with extensive knowledge of the routes, ensuring your journey is safe and seamless.",
    },
    {
      title: "How much does the cab service from Vijayawada to Hyderabad cost?",
      content:
        "The cost of our services depends on the type of vehicle and the specific options you select. Our pricing model is entirely transparent, with zero hidden fees. Upon booking, you will receive a comprehensive breakdown detailing all associated costs.",
    },
    {
      title: "Are the cabs sanitized and safe?",
      content:
        "Yes, rest assured that all our cabs undergo rigorous cleaning and sanitization procedures before and after each trip. We prioritize your safety and hygiene above all else.",
    },
    {
      title:
        "Is there a provision for carrying extra luggage?",
      content:
        "Yes. Kindly inform us at the time of booking if you have additional luggage so that we can arrange an appropriate vehicle to meet your requirements effectively.",
    },
  ];
  
  const bookingOptions = [
    {
      imgSrc: imagePaths.card1,
      title: "Safety & Comfort",
      text: (
        <>
        Your safety, ease, and comfort are our top
priorities. We track every vehicle ultimately via
GPS to ensure your peace of mind. Each vehicle
undergoes regular maintenance, with thorough
checks conducted before the start of any trip.
All Vijayawada to Hyderabad cabs have updated
and precise maintenance records to guarantee a
smooth journey for you.
        </>
      ),
    },
    {
      imgSrc: imagePaths.card2,
      title: "Highly Professional Service",
      text: (
        <span>
          Opting for chauffeur-driven rental cabs brings
distinct advantages. Our chauffeurs and
employees are highly trained professionals,
recruited only after passing multiple rigorous
tests, including driving skills, behavior
assessment, and vehicle maintenance
proficiency. They always arrive at duty
impeccably groomed and dressed in uniform.
Choosing our service means you can enjoy a
hassle-free journey with the assurance of
expertise behind the wheel.
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card3,
      title: "24/7 Service Available",
      text: (
        <span>
            At SR Car Travels, we are always just a call away
to assist you with our services. Whether you're
ready to embark on your journey or seeking
clarity, our dedicated support team is always
here for you. Contact us anytime and experience
unparalleled assistance from professionals who
care about your needs. 
        </span>
      ),
    },
   
  ];

const VijawadaToHyderabadCabBooking = ({BannerTitle}) => {
    const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

    const handleToggle = (index) => {
      setActiveKeys(
        (prevKeys) =>
          prevKeys.includes(index)
            ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
            : [...prevKeys, index], // Open the accordion if it's closed
      );
    };
  
    const toggleIcon = (index) => {
      return activeKeys.includes(index) ? (
        <i className="fa fa-minus" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-plus" aria-hidden="true"></i>
      );
    };

    return (
        <>
            <Helmet>
                <title>Vijayawada to Hyderabad Cab Booking | Vijayawada to Hyderabad Cabs</title>
                <meta name="description" content="SR Car Travels’ Vijayawada to Hyderabad Cab Booking Service ensures you are never alone on this journey; we're always here to assist." />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/vijayawada-to-hyderabad-cab-booking" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Vijayawada to Hyderabad Cab Booking | Vijayawada to Hyderabad Cabs" />
                <meta property="og:description" content="SR Car Travels’ Vijayawada to Hyderabad Cab Booking Service ensures you are never alone on this journey; we're always here to assist." />
            </Helmet> 

            <div className="banner-contents-container">
            <img
            src={imagePaths.bannerImage}
            className="banner-backdrop d-block w-100"
            loading="lazy"
            alt="Banner"
            />
            <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
            </h1>
            </div>

 
            <div className="container-fluid">
            <div className="container">
            <div className="row align-items-center">
            <div className="col-md-5">
            <LazyLoadImage
            src={imagePaths.lp1}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid"
            />
            </div>

            <div className="col-md-7 my-5 py-5 ps-5">
            <h2 class="fw-bold pb-3">
            Get Secure, Reliable Rides with SR Car Travels’ Vijayawada to Hyderabad Cab Booking Services
            </h2>
            <p className="fs-6">
            In an era where convenience is key, SR Car Travels’ Vijayawada to Hyderabad Cab Booking Services
stands out as your premier solution for seamless intercity travel. Imagine effortlessly securing a
cab that matches your preferences and budget—all with just a few taps on your device. Whether you
need an immediate ride or want to schedule one for later, our website ensures you have the perfect
car ready exactly when needed.
            </p>
            <p className="fs-6">
            Our service goes beyond merely facilitating bookings; it's anchored in trust and reliability. With a
secure system designed to protect your personal information, we prioritize safety and peace of
mind throughout your journey.
            </p> 
            <p className="fs-6">
            Explore diverse options ranging from sleek sedans and versatile hatchbacks to robust SUVs—the
choice is yours! Coupled with exceptional customer service dedicated to enhancing your
experience, traveling between Vijayawada and Hyderabad has never been this stress-free or
enjoyable. 
            </p> 
            </div>
            </div>
            </div>
            </div> 



 


 

        <h2 className="text-center mb-4 fw-bold fs-3 mt-1">
        Explore Our Cab Services
            </h2>
            <div className="container mt-5">
            {/* Outstation Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt1}
            alt="cab services in vijayawada: outstation"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Vijayawada to Hyderabad<br/> Outstation Cab Booking<br/> Service</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            With a focus on providing an effortless travel experience, SR
            Car Travels guarantees well-maintained Vijayawada to
            Hyderabad cabs and professional drivers for a smooth journey.
            Customers can easily book cabs through our user-friendly
            website, selecting the vehicle type that best suits their needs.
            Our service is renowned for its punctuality, transparent pricing,
            and exceptional customer support. This ensures long-distance
            travel between <a href="https://srcartravels.in/car-rentals-vijayawada" className="text-dark">Vijayawada and Hyderabad</a> is hassle-free and
            enjoyable.
            </p> 
            </div>
            </div>

            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Outstation Services Section */}
            {/* Local Cab Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt2}
            alt="Local Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Vijayawada to Hyderabad<br/> Airport Pickup & Drop Cab<br/>
Service</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            SR Car Travels provides dependable and efficient cab services
for Vijayawada to Hyderabad Airport pickups and drop-offs,
ensuring a hassle-free travel experience. Serving both arrivals
and departures, this service guarantees on-time transportation
so passengers can catch their flights seamlessly. With wellmaintained vehicles driven by courteous professionals, SR Car
Travels prioritizes comfort and safety throughout the journey
            </p>
            </div>
            </div>
            <hr style={thinLineStyle} />{" "}

            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt3}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Vijayawada to Hyderabad<br/>
            Round trip Cab Service</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Our team offers a reliable Vijayawada to Hyderabad <a href="https://srcartravels.in/cabs-in-vijayawada" className="text-dark">cab
booking service</a>, which is ideal for business and leisure
travelers. Enjoy seamless travel planning with our easy
booking options, including timely pickups and drop-offs to fit
your schedule. We pride ourselves on transparent pricing—no
hidden charges mean you can travel confidently. Choose SR
Car Travels for efficient, comfortable journeys between
Vijayawada and Hyderabad every time.
            </p>
            </div>
            </div>
 
            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt4}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Pancharama Kshetras Cab<br/>
            Service</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            SR Car Travels offers a dedicated service to explore the five
revered Shiva temples in Andhra Pradesh, known as the
Pancharama Kshetras. This specialized cab service provides
an exceptionally convenient and comfortable way for both
devotees and tourists to embark on this spiritual journey. Enjoy
a hassle-free pilgrimage featuring flexible schedules
accommodating stops at all key locations. Our transparent
pricing model and unwavering commitment to customer
satisfaction guarantee that your visit to the Pancharama
Kshetras will be memorable and stress-free. 
            </p>
            </div>
            </div>


 
            <hr style={thinLineStyle} />{" "}
           




            </div>





            <div className="container">
        <h2 className="my-5 text-center fs-3 fw-bold">
        Top Reasons to Choose SR Car Travels in<br/>Vijayawada
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {bookingOptions.map((option, index) => (
            <div key={index} className="col">
              <div className="card h-100 " style={{borderRadius: '30px'}}>
                <LazyLoadImage
                  src={option.imgSrc}  
                  alt={option.title}
                  effect="blur"
                  className="py-4 px-3"
                />
                <div className="card-body text-left">
                  <h3 className="card-title fs-5">{option.title}</h3>
                  <p className="card-text fs-6">{option.text}</p>
                </div>
              </div>
              
            </div>
            
          ))}
  


        </div> 
      </div>




 


            {/* Thin line after Outstation Services Section */}
            <div className="container pt-5">
            <div className="container-fluid">
            <div className="container ">
            <div className="row align-items-center ">
            {/* Image column */}
            <div className="col-md-5 ">
            <LazyLoadImage
            src={imagePaths.lpn}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid "
            />
            </div>

            {/* Content column */}
            <div className="col-md-7 my-2 py-5 ps-5">
            <h3 className="fs-3 fw-bold">
            What Makes SR Car Travels the Premier Choice
            for Your Journeys
            </h3>
            <p className="fs-6">
            SR Car Travels combines trust and reliability with unmatched convenience to deliver an exceptional
travel experience. Our comprehensive range of services—from outstation bookings and airport
transfers to specialized temple tours—ensures that all your journey needs between Vijayawada and
Hyderabad are met easily.
            </p>

            <p className="fs-6">
            Choose from our diverse fleet of vehicles, backed by rigorous maintenance standards, for your
peace of mind. Experience the professionalism of our highly trained chauffeurs, who prioritize your
safety and comfort at every turn. With round-the-clock support available, SR Car Travels’ Vijayawada
to Hyderabad Cab Booking Service ensures you are never alone on this journey; we're always here to
assist. 
            </p>
            <p className="fs-6">
            Explore more about us on our website and discover why SR Car Travels is the go-to choice for
efficient, comfortable travels in Vijayawada. Travel confidently, knowing you've chosen a service
committed entirely to enhancing your travel experience.
</p>
            <div class="d-flex align-items-center ">
            <a href="tel:+918886556632" class="me-3 text-dark">
            <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
            </a>
            <a href="mailto:contact@srcartravels.in" class="text-dark">
            <i class="bi bi-envelope-fill"></i>
            &nbsp;contact@srcartravels.in
            </a>
            </div>

            <br />
            <div>
            <a
            className="btn btn-info text-white bg-dark "
            style={{ borderRadius: "20px", border: "none" }}
            >
            Know More
            </a>
            </div>
            </div>
            </div>
            </div>
            </div>

            <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

            {/* Accordion Section */}
            <div className="accordion px-5 mx-5">
            {data.map((item, index) => (
            <div key={index} class="py-2">
            <button
            className="accordion-button fs-4 text-dark"
            type="button"
            onClick={() => handleToggle(index)}
            aria-expanded={activeKeys.includes(index) ? "true" : "false"}
            aria-controls={`collapse${index}`}
            >
            <span className="accordion-title">{item.title}</span>
            <span className="accordion-icon">{toggleIcon(index)}</span>
            </button>
            {activeKeys.includes(index) && (
            <div className="accordion-content">{item.content}</div>
            )}
            </div>
            ))}
            </div>
            </div>
        </>
    );
};

export default VijawadaToHyderabadCabBooking;
