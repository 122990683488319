import React from 'react';
import { Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const RentACarCsv = () => {
    const imagePath = require("../assets/img/csv_img_9.png"); // Update the path to your actual image location

    return (
        <div className="container py-5">
            <div className="row align-items-center">
                {/* Image column to the left */}
                <div className="col-md-4">
                    <LazyLoadImage
                        alt="Rent a Car in Guntur"
                        src={imagePath}
                        effect="blur"
                        className="img-fluid rounded"
                        style={{ width: '350px', height: '330px' }}
                    />
                </div>
                {/* Content column with a small gap on the left */}
                <div className="col-md-8 ps-md-4"> {/* Adjust the gap as needed */}
                    <h4 className="mb-2 fs-5">Summing It Up</h4>
                    <p className='fs-6'>SR Car Travels is your go-to solution for reliable and budget-friendly intercity cab services in Vijayawada. Take advantage of exclusive discounts and explore Vijayawada with your loved ones, or simply book our cabs for convenient airport and railway station transfers. With just a few clicks, you can access our city cab services and explore all Vijayawada offers.</p>
                    <p className='fs-6'>Explore our wide range of taxi options and make the most of your travels with SR Car Travels.</p>
                    <div className="d-flex flex-column flex-sm-row align-items-center gap-2">
                        <Button variant="outline-primary" href="tel:+918886556632">
                            <i className="bi bi-telephone-fill"></i> Call Now: +91-8886556632
                        </Button>
                        <Button variant="outline-primary" href="mailto:contact@srcartravels.in">
                            <i className="bi bi-envelope-fill"></i> Email Us
                        </Button>
                    </div>
                    <br />
                    <a
                        href="https://srcartravels.in/contact-us"
                        class="btn btn-primary"
                        variant="primary"
                        style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                    >
                        Know More
                    </a>
                </div>
            </div>
        </div>
    );
};

export default RentACarCsv;
