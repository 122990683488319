import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';
import "./KeyWordStyle.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
    bannerImage: require("../assets/img/BannerBestCarTravelsinVijayawada.png"),
    via1:require("../assets/img/lp3/viaweb.png"),
    via2:require("../assets/img/lp3/viaphone.png"),
    via3:require("../assets/img/lp3/viamail.png"),
    lp1: require("../assets/img/lp3/lp-1.png"),
    lp2: require("../assets/img/lp3/lp-2.png"), 
    lpt1: require("../assets/img/lp3/ic_1.png"),
    lpt2: require("../assets/img/lp3/ic_2.png"),
    lpt3: require("../assets/img/lp3/ic_3.png"),
    lpt4: require("../assets/img/lp2/ic_4.png"), 
    card1: require("../assets/img/lp3/card_1.png"), 
    card2: require("../assets/img/lp3/card_2.png"), 
    card3: require("../assets/img/lp3/card_3.png"),    
  };
  
  
  // Custom style for the thin line
  const thinLineStyle = {
    width: "75%",
    margin: "20px auto", // Combined marginTop and marginBottom
    height: "1px",
    backgroundColor: "#ddd",
  };
  
  const data = [
    {
      title:
        "Is there customer support available if I encounter any issues during my trip?",
      content:
        "Our dedicated 24/7 customer support team is always available to assist you with any issues or adjustments to your travel plans.",
    },
    {
      title: "Do you offer services outside Vijayawada for nearby tourist spots?",
      content:
        "We provide extended tours to various nearby tourist destinations outside Vijayawada. For detailed information regarding specific destinations and available packages, please feel free to inquire. ",
    },
    {
      title: "Do you also provide airport pick up and drop off services?",
      content:
        "Our dependable airport transfer services guarantee a seamless beginning and conclusion to your travel experience.",
    },
    {
      title: "What safety measures do you have in place for passengers?",
      content:
        "At SR Car Travels, we prioritize passenger safety through meticulous adherence to rigorous safety protocols. Our commitment includes regular and thorough vehicle maintenance, ensuring optimal cleanliness standards and conducting comprehensive background checks on all our drivers. ",
    },
    {
      title:
        "How can I book a car with SR Car Travels?",
      content:
        "Secure your vehicle effortlessly by choosing from three convenient options: book directly through our website, contact our team via phone, or visit our office in Vijayawada. ",
    },
  ];
  
  const bookingOptions = [
    {
      imgSrc: imagePaths.card1,
      title: "Assured Sedan (Dzire/Etios)",
      text: (
        <>
        Whether you're traveling for business or leisure, our meticulously maintained fleet of sedans guarantees a smooth and enjoyable ride. Featuring spacious interiors, advanced safety systems, and fuel-efficient engines, our Dzire and Etios models offer the ideal combination of comfort and performance for your journey. From city tours to airport transfers, our best car travels in Vijayawada, Assured Sedan service, promises a hassle-free travel experience. 
        </>
      ),
    },
    {
      imgSrc: imagePaths.card2,
      title: "Assured SUV (Ertiga)",
      text: (
        <span>
            Discover unparalleled comfort and safety with our
            Assured SUV service. Outfitted with state-of-the-art
            safety features and contemporary amenities, our
            vehicles guarantee a secure, smooth ride throughout
            Vijayawada and its picturesque surroundings.
            Experience the epitome of reliability as you journey
            confidently in our Assured SUVs, ensuring your next
            trip to Vijayawada is memorable and enjoyable.
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card3,
      title: "Assured Innova",
      text: (
        <span>
           Perfect for family vacations, business trips, or group outings, the spacious and elegantly designed Innova offers a smooth and enjoyable ride. Equipped with toptier safety features and modern amenities, our Assured Innova promises an elevated travel experience. Opt for Assured Innova for your next journey to enjoy unparalleled comfort and reliability. 
        </span>
      ),
    } 
  ];


const SRBestCarTravelsInVijayawada = ({BannerTitle}) => {
    const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

    const handleToggle = (index) => {
      setActiveKeys(
        (prevKeys) =>
          prevKeys.includes(index)
            ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
            : [...prevKeys, index], // Open the accordion if it's closed
      );
    };
  
    const toggleIcon = (index) => {
      return activeKeys.includes(index) ? (
        <i className="fa fa-minus" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-plus" aria-hidden="true"></i>
      );
    };

    return (
        <>
            <Helmet>
                <title>Best Car Travels in Vijayawada | SR Car Travels</title>
                <meta name="description" content="SR Car Travels is the best Premium Car Travels in Vijayawada, offering cars sedan, SUV, Innova etc. Rent affordable rental cars today. Plan your journey now." />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/best-car-travels-in-vijayawada" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Best Car Travels in Vijayawada | SR Car Travels" />
                <meta property="og:description" content="SR Car Travels is the best Premium Car Travels in Vijayawada, offering cars sedan, SUV, Innova etc. Rent affordable rental cars today. Plan your journey now." />
            </Helmet> 

            <div className="banner-contents-container">
            <img
            src={imagePaths.bannerImage}
            className="banner-backdrop d-block w-100"
            loading="lazy"
            alt="Banner"
            />
            <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
            </h1>
            </div>

            <div className="container-fluid">
            <div className="container">
            <div className="row align-items-center">
            <div className="col-md-5">
            <LazyLoadImage
            src={imagePaths.lp1}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid"
            />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
            <h2 class="fw-bold">
            Find the Perfect Ride for Your Adventure with the Best Car Travels in Vijayawada
            </h2>
            <p className="fs-6">
            Are you planning a trip to Vijayawada and seeking the perfect travel service to make your journey seamless? Look no further than the SR Car Travels in Vijayawada.
            </p>
            <p className="fs-6">
            At SR Car Travels, a memorable journey starts with the ideal ride. Whether navigating the energetic cityscape or venturing into its beautiful outskirts, our wide range of vehicles is designed to meet all your travel needs. With skilled drivers, tailored itineraries, and unparalleled customer support, we are dedicated to providing an exceptional travel experience from start to finish
            </p>
            <p className="fs-6">
            Ready to find your perfect ride? Explore the best car travels in Vijayawada and book your
            unforgettable city adventure today! 
            </p>
            </div>
            </div>
            </div>
            </div> 

            <div className="container py-4">
            <h2 className="mb-4 text-center fs-3 fw-bold">
            Explore Different Vehicle Options for Your Needs
            </h2>
            <div className="row row-cols-1 row-cols-md-3 g-4">
            {bookingOptions.map((option, index) => (
            <div key={index} className="col">
            <div className="card h-100 border-0">
            <LazyLoadImage
            src={option.imgSrc}  
            alt={option.title}
            effect="blur"
            className="card-img px-2"
            />
            <div className="card-body text-left">
            <h3 className="card-title fs-5">{option.title}</h3>
            <p className="card-text fs-6">{option.text}</p>
            </div>
            </div>

            </div>

            ))} 

            </div> 
            </div>



            <div className="container-fluid">
            <div className="container">
            <h2 className=" text-center fs-3 fw-bold">
            Stress-Free Car Rentals in Vijayawada: 24/7<br/> Booking with SR Car Travels
            </h2>

            <div className="row align-items-center">
           

            <div className="col-md-5">
            <LazyLoadImage
            src={imagePaths.via1}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid"
            />
            </div>

            <div className="col-md-7 my-2 py-3 px-5">
            <h5 class="fw-bold">
            Via Website
            </h5>
            <p className="fs-6">
            Looking to <a href="https://srcartravels.in/car-rentals-in-vijayawada">rent a car in Vijayawada</a> for outstation or local travel? Simply visit our website & enter your pickup location, destination, and travel date. Select the perfect car from our rental inventory based on your preferences and requirements. Our website offers transparent pricing details with each car model, ensuring you secure the best deal for your upcoming journey
            </p>
             
            </div>

            
            </div>



            <div className="row align-items-center">
           



           <div className="col-md-7 my-2 py-3 pe-5">
           <h5 class="fw-bold">
           Via Phone
           </h5>
           <p className="fs-6">
           To conveniently book your upcoming journey directly with our executives, please contact us at <a href="tel:+918886556632" class="text-dark">+91-8886556632</a>. Kindly share your details with our best car travels in Vijayawada dedicated team, who will provide comprehensive assistance throughout the booking process. 
           </p>
            
           </div>



           <div className="col-md-5">
           <LazyLoadImage
           src={imagePaths.via2}
           alt="Car Rentals in Vijayawada"
           effect="blur"
           width="100%"
           height="auto"
           style={{ borderRadius: "25px" }}
           className="img-fluid"
           />
           </div>
           
           
           </div>



           <div className="row align-items-center">
           

           <div className="col-md-5">
           <LazyLoadImage
           src={imagePaths.via3}
           alt="Car Rentals in Vijayawada"
           effect="blur"
           width="100%"
           height="auto"
           style={{ borderRadius: "25px" }}
           className="img-fluid"
           />
           </div>


           <div className="col-md-7 my-2 py-3 ps-5">
           <h5 class="fw-bold">
           Via Email
           </h5>
           <p className="fs-6">
           If you have any inquiries or need assistance with planning your itinerary, please feel free to reach out to us at <a href="mailto:contact@srcartravels.in" class="text-dark">contact@srcartravels.in</a>. Our dedicated executives are ready and respond promptly, ensuring all your needs are addressed. We look forward to providing exceptional service for you.
           </p>
            
           </div>



           
           
           </div>



            
            </div>
            </div> 


            <h2 className="text-center mb-4 fw-bold fs-3 mt-5">
            Our Car Rental Services
            </h2>
            <div className="container mt-5">
            {/* Outstation Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt1}
            alt="cab services in vijayawada: outstation"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Local Sightseeing Service in
            Vijayawada</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Our  <a href="https://srcartravels.in/vijayawada-city-tour" class="text-dark">local sightseeing services</a> ensure you explore every nook and corner of Vijayawada comfortably and stylishly. Whether it's the majestic Kanaka Durga Temple or the serene Undavalli Caves, embark on an unforgettable journey with us.
            </p> 
            </div>
            </div>

            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Outstation Services Section */}
            {/* Local Cab Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt2}
            alt="Local Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Outstation Services</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            From weekend getaways to extended tours, SR Car Travels provides a diverse fleet of vehicles tailored to your travel needs, ensuring each journey is an adventure. Experience the freedom and comfort of the open road with our reliable service.
            </p>
            </div>
            </div>
            <hr style={thinLineStyle} />{" "}

            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt3}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Seamless Airport Transfers</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Bid goodbye to the stress of airport commutes with our dependable and best car travels in Vijayawadas airport transfer services. SR Car Travels guarantees timely, comfortable, and seamless journeys to and from Vijayawada Airport. Allow us to manage the travel logistics so you can unwind and focus on your upcoming trip.
            </p>
            </div>
            </div>

 


            <hr style={thinLineStyle} />{" "}



            </div>



            {/* Thin line after Outstation Services Section */}
            <div className="container pt-5">
            <div className="container-fluid">
            <div className="container ">
            <div className="row align-items-center ">
            {/* Image column */}
            <div className="col-md-5 ">
            <LazyLoadImage
            src={imagePaths.lp2}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid "
            />
            </div>

            {/* Content column */}
            <div className="col-md-7 my-2 p-5">
            <h3 className="fs-3 fw-bold">
            Why SR Car Travels is Your Best Choice for Memorable Journeys
            </h3>
            <p className="fs-6">
            SR Car Travels is committed to making every journey memorable by providing the perfect ride for all your travel needs in and around Vijayawada. Our diverse fleet of meticulously maintained sedans, SUVs, and Innovas ensures you travel comfortably and safely. 
            </p>

            <p className="fs-6">
            Whether planning a business trip or family vacation or simply exploring the vibrant cityscape or scenic outskirts, our tailored itineraries and skilled drivers guarantee an exceptional experience from start to finish. 
            </p>

            <p className="fs-6">
            Choose SR Car Travels, not just as a transportation service but as an integral part of creating cherished memories during your journeys every mile is smoothly covered under exemplary care! 
            </p>

            <div class="d-flex align-items-center ">
            <a href="tel:+918886556632" class="me-3 text-dark">
            <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
            </a>
            <a href="mailto:contact@srcartravels.in" class="text-dark">
            <i class="bi bi-envelope-fill"></i>
            &nbsp;contact@srcartravels.in
            </a>
            </div>

            <br />
            <div>
            <a
            className="btn btn-info text-white bg-dark "
            style={{ borderRadius: "20px", border: "none" }}
            >
            Know More
            </a>
            </div>
            </div>
            </div>
            </div>
            </div>

            <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

            {/* Accordion Section */}
            <div className="accordion px-5 mx-5">
            {data.map((item, index) => (
            <div key={index} class="py-2">
            <button
            className="accordion-button fs-4 text-dark"
            type="button"
            onClick={() => handleToggle(index)}
            aria-expanded={activeKeys.includes(index) ? "true" : "false"}
            aria-controls={`collapse${index}`}
            >
            <span className="accordion-title">{item.title}</span>
            <span className="accordion-icon">{toggleIcon(index)}</span>
            </button>
            {activeKeys.includes(index) && (
            <div className="accordion-content">{item.content}</div>
            )}
            </div>
            ))}
            </div>
            </div>
        </>
    );
};

export default SRBestCarTravelsInVijayawada;
