import React from 'react';
import { Helmet } from 'react-helmet';

const SROutstationServices = () => {
  const destinations = [
    {
      id: 'hyderabad',
      title: 'Vijayawada to Hyderabad - Outstation Taxi Services',
      description: (
        <>
          Explore the rich history and vibrant culture of Hyderabad with our Outstation <a href="https://srcartravels.in/cabs-from-vijayawada-to-hyderabad" style={{ color: 'inherit', textDecoration: 'underline' }}>Cab Services from Vijayawada</a>. Don't miss the iconic Charminar and Golconda Fort. Experience a reliable and comfortable journey with our taxi service at your fingertips.
        </>
      ),
      images: [
        '/assets/img/hyderabad/slide-1.png',
        '/assets/img/hyderabad/slide-2.png',
        '/assets/img/hyderabad/slide-3.png'
      ],
      bookNowLink: '/contact-us',
    },
    {
      id: 'tirupati',
      title: 'Vijayawada to Tirupati - Divine Journey Services',
      description: (
        <>
          Embark on a spiritual journey from Vijayawada to the sacred city of Tirupati. Experience the divine with our <a href="https://srcartravels.in/" style={{ color: 'inherit', textDecoration: 'underline' }}>comfortable taxi services</a>, ensuring a serene travel experience to visit Lord Venkateswara Temple.
        </>
      ),
      images: [
        '/assets/img/tirupati/slide-1.png',
        '/assets/img/tirupati/slide-2.png',
        '/assets/img/tirupati/slide-3.png'
      ],
      bookNowLink: '/contact-us',
    },
    {
      id: 'ooty',
      title: 'Ooty',
      description: 'Experience the hill station charm of Ooty with its pleasant weather, tea gardens, and scenic landscapes.',
      images: ['/assets/img/ooty/slide-1.png', '/assets/img/ooty/slide-2.png', '/assets/img/ooty/slide-3.png', '/assets/img/ooty/slide-4.png', '/assets/img/ooty/slide-5.png'],
      bookNowLink: '/contact-us',
    },
    {
      id: 'goa',
      title: 'Goa',
      description: 'Relax on the beautiful beaches, enjoy water sports, and savor the nightlife in the picturesque state of Goa.',
      images: ['/assets/img/goa/slide-1.png', '/assets/img/goa/slide-2.png', '/assets/img/goa/slide-3.png', '/assets/img/goa/slide-4.png', '/assets/img/goa/slide-5.png'],
      bookNowLink: '/contact-us',
    },
    {
      id: 'araku',
      title: 'Araku Valley',
      description: 'Visit the serene Araku Valley, surrounded by lush coffee plantations and waterfalls, a perfect getaway from the city.',
      images: [
        '/assets/img/araku/slide-1.png',
        '/assets/img/araku/slide-2.png',
        '/assets/img/araku/slide-3.png'
      ],
      bookNowLink: '/contact-us',
    },
    {
      id: 'vishakapatnam',
      title: 'Visakhapatnam',
      description: "Discover the coastal beauty and historical landmarks in Visakhapatnam, often called the 'Jewel of the East Coast.'",
      images: [
        '/assets/img/vishakapatnam/slide-1.png',
        '/assets/img/vishakapatnam/slide-2.png',
        '/assets/img/vishakapatnam/slide-3.png'
      ],
      bookNowLink: '/contact-us',
    },
    {
      id: 'bangalore',
      title: 'Banglore',
      description: "Visit the 'Silicon Valley of India' for its tech hubs, botanical gardens, and a thriving nightlife scene.",
      images: ['/assets/img/banglore/slide-1.png', '/assets/img/banglore/slide-2.png', '/assets/img/banglore/slide-3.png'],
      bookNowLink: '/contact-us',
    },
    {
      id: 'chennai',
      title: 'Chennai',
      description: 'Explore the capital of Tamil Nadu, known for its historic temples, beautiful beaches, and vibrant culture.',
      images: ['/assets/img/chennai/slide-1.png', '/assets/img/chennai/slide-2.png', '/assets/img/chennai/slide-3.png'],
      bookNowLink: '/contact-us',
    },
    {
      id: 'mysore',
      title: 'Mysore',
      description: "Mysore, often referred to as the 'City of Palaces,' is a captivating city in Karnataka, India, known for its rich cultural heritage, grand palaces, and vibrant festivities. Here's what you can explore in Mysore:",
      images: [
        '/assets/img/mysore/slide-1.png',
        '/assets/img/mysore/slide-2.png',
        '/assets/img/mysore/slide-3.png',
      ],
      bookNowLink: '/contact-us',
    },

  ];

  return (
    <>
      <Helmet>
        <title>Outstation | Vijayawada Taxi Services | Hyd to Vijayawada Cabs</title>
        <meta name="description" content="SR Car Travels offers a wide range of outstation services. Avail special discounts on hiring a car for a safe and comfortable. Book a cab at anytime, taxi services in Vijayawada." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://srcartravels.in/outstation" />

        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta property="og:title" content="Outstation | Vijayawada Taxi Services | Hyd to Vijayawada Cabs" />
        <meta property="og:description" content="SR Car Travels offers a wide range of outstation services. Avail special discounts on hiring a car for a safe and comfortable. Book a cab at anytime, taxi services in Vijayawada." />
      </Helmet>
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1>Our Outstation Services</h1>
            <p><strong>We offer a wide range of outstation services, including one-way trips, round trips, and customizable itineraries to suit your preferences and needs.</strong></p>
          </div>
        </div>
        <hr className="rounded" />
        <div className="row">
          {destinations.map((destination, index) => (
            <div key={index} className="col-md-6 mb-3">
              <div className="card">
                <div id={`carousel${destination.id}`} className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                    {destination.images.map((_, idx) => (
                      <button key={idx} type="button" data-bs-target={`#carousel${destination.id}`} data-bs-slide-to={idx} className={idx === 0 ? 'active' : ''} aria-label={`Slide ${idx + 1}`}></button>
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {destination.images.map((img, idx) => (
                      <div key={idx} className={`carousel-item ${idx === 0 ? 'active' : ''}`}>
                        <img src={img} className="d-block w-100" alt={`${destination.title} slide ${idx + 1}`} />
                      </div>
                    ))}
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target={`#carousel${destination.id}`} data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target={`#carousel${destination.id}`} data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{destination.title}</h5>
                  <p className="card-text">{destination.description}</p>
                  <a href={destination.bookNowLink} className="btn btn-primary">Book Now</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SROutstationServices;
