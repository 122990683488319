import React, {  } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import SRCustomForm from '../components/common/SRCustomForm';
import FAQsComponentForLongDriveCarsVij from './FAQsComponentForLongDriveCarsVij';
import SRTravelOptionsForLdc from './SRTravelOptionsForLdc';
import { Button } from 'react-bootstrap';

// Assuming these paths are correct relative to this component file
const imagePaths = [
    require("../assets/img/ldc-1.png"),
    require("../assets/img/ldc-e-9.png"),
    require("../assets/img/ldc-6.png"),
    require("../assets/img/ldc-7.png"),
    require("../assets/img/ldc-8.png"),
];

const customImagePaths = {  
    bannerImage: require("../assets/img/Long Drive Cars Vijayawada_Banner.png") 
};

const LongDriveCarsVijayawada = () => {

    return (
        <>
            <Helmet>
                <title>Long Drive Cars Vijayawada | SR Car Travels</title>
                <meta name="description" content="Discover the lively city of Vijayawada and beyond with SR long drive cars Vijayawada Travels, is your ultimate destination for self-drive car rentals." />
                <link rel="canonical" href="https://srcartravels.in/long-drive-cars-vijayawada" />
                <meta name="robots" content="index, follow" />

            </Helmet>
            <SRCustomForm BannerTitle="Long Drive Cars Vijayawada" BannerImg={customImagePaths.bannerImage}/>
            <br/><br/><br/><br/>
            <div className="container py-5" style={{ top: '606px' }}>
                <div className="row">
                    <div className="col-lg-4 d-flex justify-content-end">
                        <LazyLoadImage
                            src={imagePaths[0]}
                            alt="Long Drive Cars Vijayawada Services"
                            effect="blur"
                            width="1440" // Width as per layout properties
                            height="464" // Height as per layout properties
                            className="img-fluid rounded"
                            style={{ borderRadius: "20px" }} // Assuming you want to keep the border radius
                        />
                    </div>
                    <div className="col-lg-8">
                        <h2 className="fs-1 fw-bold mb-3">Experience Ultimate Comfort & Convenience with Our Long Drive Cars Vijayawada Services</h2>
                        <p className="fs-6">In the bustling city of Vijayawada, finding the perfect <a href="https://srcartravels.in/car-rentals-vijayawada" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>car rental service</a> can make all the difference for your upcoming excursions. If you're a traveler passionate about discovering new destinations, you'll want to ensure that your travel experience is seamless and hassle-free.</p>
                        <p className="fs-6">SR Car Travels is a leading car rental company with a vibrant fleet of self-drive cars. Whether you're venturing out for a destined exploration or simply discovering the city, our long drive cars Vijayawada Services, offers unlimited kilometers and doorstep delivery to elevate your travel experience.</p>
                    </div>

                </div>
            </div>

            <SRTravelOptionsForLdc />
            <div className="container py-3">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <LazyLoadImage
                            alt="Rent a Car in Guntur"
                            src={imagePaths[2]}
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ width: '350px', height: '330px' }}
                        />
                    </div>
                    <div className="col-lg-8 ps-lg-3"> {/* Adjusted for content alignment and added padding */}
                        <h2 className='fs-bold fs-5'>SR Car Travels’ Services in Vijayawada</h2> {/* Changed to H2 as requested */}
                        <ul>
                            <li>Local Sightseeing in the City</li>
                            <li>Smooth Airport Transfers</li>
                            <li><a href="https://srcartravels.in/outstation" style={{ textDecoration: 'none' }}>Outstation Travel Adventures</a></li>
                        </ul>

                        <a
                            href="https://srcartravels.in/contact-us"
                            className="mt-3 btn btn-primary"
                            variant="primary"
                            style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                            onClick={() => window.location.href = "https://srcartravels.in/car-rentals-vijayawada"}
                        >
                            Know More
                        </a>
                    </div>
                </div>
            </div>

            <div className="rental-service-section" style={{ backgroundColor: '#eef4f7', padding: '40px', borderRadius: '15px', maxWidth: '1171px', margin: 'auto' }}>
                <div className="row">
                    <div className="col-md-8">
                        <h2>Rent the Perfect Car for Your Vijayawada Trip with SR Car Travels</h2>
                        <p>With a commitment to excellence and affordability, we provide a diverse range of vehicle options, including Sedans, SUVs, Mini SUVs, and luxury cars such as Innova and Ertiga. Not only do we prioritize top-notch service, but we also eliminate the complexities of refueling, hidden costs, and breakdown concerns, backed by 24/7 roadside assistance.</p>
                        <p><a href="https://srcartravels.in/vijayawada-city-tour" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>Discover the lively city of Vijayawada</a> and beyond with SR long drive cars Vijayawada Travels, is your ultimate destination for self-drive car rentals. Unleash your adventures with the convenience of exploring the scenic delights at your own pace.</p>
                        <a
                            className="btn btn-primary"
                            style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                            href="https://srcartravels.in/car-rentals-vijayawada"
                        >
                            Know More
                        </a>
                    </div>
                    <div className="col-md-4">
                        <LazyLoadImage
                            src={imagePaths[3]}
                            alt="SR Car Travels"
                            effect="blur"
                            className="img-fluid rounded"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>
                </div>
            </div>
            
            <div className="rental-service-banner py-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: '10px', overflow: 'hidden', maxWidth: '1123px', margin: 'auto' }}>
                <div style={{ flex: '1' }}>
                    <LazyLoadImage
                        src={imagePaths[4]}
                        alt="Car on Highway"
                        effect="blur"
                        className="img-fluid"
                        style={{ height: '100%', objectFit: 'cover' }}
                    />
                </div>
                <div style={{ flex: '1', padding: '20px' }}>
                    <h2>SR Car Travels: Your Trusted Partner for Hassle-Free Car Rentals in Vijayawada</h2>
                    <p>SR Long Drive Cars Vijayawada Services stands out as a <a href="https://srcartravels.in/best-car-travels-in-vijayawada" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>premier choice in the city</a>, offering an array of self-drive cars with unlimited kilometers and convenient doorstep delivery. With a diverse range of vehicles, like Sedans, SUVs, Mini SUVs, and luxury cars like Innova and Ertiga, SR Car Travels ensures both excellence and affordability.</p>
                    <p>Experience the convenience of booking with <a href="https://srcartravels.in/" style={{ color: 'black', textDecoration: 'underline', textDecorationColor: 'lightgray' }}>SR Car Travels</a>, where our transparent pricing and unwavering commitment to customer service guarantee a stress-free journey from beginning to end. Select us for your upcoming trip in Vijayawada and revel in the assurance of a trustworthy car rental service that allows you to focus on savoring the local scenic delights.</p>
                    <div className="contact-info">
                        <p><a href="tel:+918886556632" className="btn btn-primary">📞 +91-8886556632</a></p>
                        <p><a href="mailto:contact@srcartravels.in" className="btn btn-primary">📧 contact@srcartravels.in</a></p>
                    </div>

                    <a
                        className="btn btn-primary"
                        style={{ backgroundColor: '#113042', borderColor: '#113042' }}
                        href="https://srcartravels.in/car-rentals-vijayawada"
                    >
                        Know More
                    </a>
                </div>
            </div>
            <FAQsComponentForLongDriveCarsVij />
        </>
    );
};

export default LongDriveCarsVijayawada;
