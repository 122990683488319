import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from 'react-helmet';
import "./KeyWordStyle.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
    bannerImage: require("../assets/img/BannerHyderabadtoVijayawadaTaxi.png"),
    via1:require("../assets/img/lp6/viaweb.png"),
    via2:require("../assets/img/lp6/viaphone.png"), 
    lp1: require("../assets/img/lp6/lp-1.png"),
    lp2: require("../assets/img/lp6/lp-2.png"), 
    lpn: require("../assets/img/lp6/lp-n.png"),
    lpt1: require("../assets/img/lp6/ic_1.png"),
    lpt2: require("../assets/img/lp6/ic_2.png"),
    lpt3: require("../assets/img/lp6/ic_3.png"),
    lpt4: require("../assets/img/lp6/ic_4.png"), 
    card1: require("../assets/img/lp3/card_1.png"), 
    card2: require("../assets/img/lp3/card_2.png"), 
    card3: require("../assets/img/lp3/card_3.png"),    
  };
  
  
  // Custom style for the thin line
  const thinLineStyle = {
    width: "75%",
    margin: "20px auto", // Combined marginTop and marginBottom
    height: "1px",
    backgroundColor: "#ddd",
  };
  
  const data = [
    {
      title:
        "Do I have to pay the total charge, or is there any one-way fare?",
      content:
        "At SR Car Travels, you only pay for the distance traveled based on your specific trip plan. For instance, if you plan a one-way journey from Hyderabad to Vijayawada, you'll be charged solely for that travel segment",
    },
    {
      title: "How can I pay for my trip from Hyderabad to Vijayawada with SR Car Travels?",
      content:
        "You can settle your fare for an Uber Intercity journey from Hyderabad to Vijayawada using cash, UPIs, or Credit and Debit Cards.",
    },
    {
      title: "Is it safe to book outstation cabs with SR Car Travels?",
      content:
        "At SR Car Travels, your safety is our foremost priority. When you book a ride with us, you can expect the finest cabs and unparalleled safety measures to ensure a secure journey every time. ",
    },
    {
      title: "What is the distance from Hyderabad to Vijayawada by outstation cab?",
      content:
        "The typical distance from Hyderabad to Vijayawada when traveling by outstation cab is approximately 298 kilometers. This average distance can fluctuate due to traffic conditions and other variable factors",
    },
    {
      title:
        "Can I also book a round-trip ride from Hyderabad to Vijayawada with SR Car Travels? ",
      content:
        " You can conveniently request a round-trip ride with SR Car Travels. Please check the website to confirm your booking.",
    },
  ];
  
  const bookingOptions = [
    {
      imgSrc: imagePaths.card1,
      title: "Assured Sedan (Dzire/Etios)",
      text: (
        <>
        Whether you're traveling for business or leisure, our meticulously maintained fleet of sedans guarantees a smooth and enjoyable ride. Featuring spacious interiors, advanced safety systems, and fuel-efficient engines, our Dzire and Etios models offer the ideal combination of comfort and performance for your journey. From city tours to airport transfers, our best car travels in Vijayawada, Assured Sedan service, promises a hassle-free travel experience. 
        </>
      ),
    },
    {
      imgSrc: imagePaths.card2,
      title: "Assured SUV (Ertiga)",
      text: (
        <span>
            Discover unparalleled comfort and safety with our
            Assured SUV service. Outfitted with state-of-the-art
            safety features and contemporary amenities, our
            vehicles guarantee a secure, smooth ride throughout
            Vijayawada and its picturesque surroundings.
            Experience the epitome of reliability as you journey
            confidently in our Assured SUVs, ensuring your next
            trip to Vijayawada is memorable and enjoyable.
        </span>
      ),
    },
    {
      imgSrc: imagePaths.card3,
      title: "Assured Innova",
      text: (
        <span>
           Perfect for family vacations, business trips, or group outings, the spacious and elegantly designed Innova offers a smooth and enjoyable ride. Equipped with toptier safety features and modern amenities, our Assured Innova promises an elevated travel experience. Opt for Assured Innova for your next journey to enjoy unparalleled comfort and reliability. 
        </span>
      ),
    } 
  ];


const HyderabadToVijayawadaTaxi = ({BannerTitle}) => {
    const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

    const handleToggle = (index) => {
      setActiveKeys(
        (prevKeys) =>
          prevKeys.includes(index)
            ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
            : [...prevKeys, index], // Open the accordion if it's closed
      );
    };
  
    const toggleIcon = (index) => {
      return activeKeys.includes(index) ? (
        <i className="fa fa-minus" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-plus" aria-hidden="true"></i>
      );
    };

    return (
        <>
            <Helmet>
                <title>Hyderabad to Vijayawada Taxi - SR Car Travels</title>
                <meta name="description" content="Traveling outstation on weekends doesn't have to be a hassle. SR Car Travels’ Hyderabad to Vijayawada Taxi Services are here to make your journey seamless and stress-free" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://srcartravels.in/hyderabad-to-vijayawada-taxi" />

                <meta property="og:site_name" content="SR Car Travels" />
                <meta property="og:url" content="https://srcartravels.in/" />
                <meta property="og:title" content="Hyderabad to Vijayawada Taxi - SR Car Travels" />
                <meta property="og:description" content="Traveling outstation on weekends doesn't have to be a hassle. SR Car Travels’ Hyderabad to Vijayawada Taxi Services are here to make your journey seamless and stress-free" />
            </Helmet> 

            <div className="banner-contents-container">
            <img
            src={imagePaths.bannerImage}
            className="banner-backdrop d-block w-100"
            loading="lazy"
            alt="Banner"
            />
            <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
            </h1>
            </div>

            <div className="container-fluid">
            <div className="container">
            <div className="row align-items-center">
            <div className="col-md-5">
            <LazyLoadImage
            src={imagePaths.lp1}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid"
            />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
            <h2 class="fw-bold">
            Weekend Getaways Made Easy with SR Car Travels’ Hyderabad to Vijayawada Taxi Services
            </h2>
            <p className="fs-6">
            Traveling outstation on weekends doesn't have to be a hassle. SR Car Travels’ Hyderabad to
            Vijayawada Taxi Services are here to make your journey seamless and stress-free. With our roundthe-clock availability and expert drivers, you can sit back, relax, and enjoy the scenery along the way.
            Our diverse range of ride options ensures that you'll find the perfect vehicle for your needs, whether
            you're traveling solo or with a group. Choose from spacious SUVs that can accommodate up to 6
            passengers or opt for a comfortable sedan that's ideal for up to 4 people.

            No matter the reason for your trip—be it a leisurely road trip, an important business meeting, an
            airport transfer, or simply a change of scenery—<a href="https://srcartravels.in/" className="text-dark">SR Car Travels’</a> Hyderabad to Vijayawada Taxi
            Service is your go-to solution for convenient and trustable transportation.
            </p>
            </div>
            </div>
            </div>
            </div> 

    


            <div className="container-fluid">
            <div className="container">
             
            <div className="row align-items-center py-3">
           

          

            <div className="col-md-7">
            <h3 class="fw-bold">
            SR Car Travels’ One-Way Taxi Service
            </h3>
            <p className="fs-6">
            If you are uncertain about your return journey or need to travel on a one-way trip, our one-way car
            rental services offer an excellent solution while helping you save money. For instance, you can book
            our Hyderabad to Vijayawada one-way taxi service and enjoy fares that are nearly half what a round
            trip would cost. Choose SR Car Travels for reliable, cost-effective transportation solutions tailored
            to your needs
            </p>
             
            </div>
            <div className="col-md-5">
            <LazyLoadImage
            src={imagePaths.via1}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid"
            />
            </div>
            
            </div>



      


           <div className="row align-items-center py-3">
           

           <div className="col-md-5">
           <LazyLoadImage
           src={imagePaths.via2}
           alt="Car Rentals in Vijayawada"
           effect="blur"
           width="100%"
           height="auto"
           style={{ borderRadius: "25px" }}
           className="img-fluid"
           />
           </div>


           <div className="col-md-7 my-2 py-3 ps-5">
           <h3 class="fw-bold">
           SR Car Travels’ Round Trip Taxi Service
           </h3>
           <p className="fs-6">
           SR Car Travels offers a top-notch round-trip <a href="https://srcartravels.in/outstation" className="text-dark">taxi service</a> from Hyderabad to Vijayawada, which is
          ideally suited for business and leisure travelers. Renowned for their punctuality and professionalism,
          SR Car Travels ensures each journey is comfortable, courtesy of their well-maintained vehicles and
          skilled chauffeurs. With competitive pricing and a commitment to customer satisfaction, SR Car
          Travels is the preferred choice for intercity travel in this region. 
           </p>
            
           </div>



           
           
           </div>



            
            </div>
            </div> 




            <div className="">
            <div className="container ">
            <h3 className="fs-3 fw-bold text-center">
            Why is a road trip the Perfect Way to See <br/>Hyderabad & Vijayawada?
            </h3>
            <p className="fs-6  text-center  px-md-5 px-0 mx-md-5 mx-0 py-4">Embark on a memorable journey from Hyderabad to Vijayawada, a 272 km drive that typically takes around 4.5 hours. The wellmaintained roads promise a smooth and pleasant trip, and fascinating landmarks like Bhavani Island, Kanaka Durga Temple, and
            Amaravathi enrich your travel experience.</p>
            <div className="row align-items-center ">
            {/* Image column */}
            <div className="col-md-5 ">
            <LazyLoadImage
            src={imagePaths.lp2}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid "
            />
            </div>

            {/* Content column */}
            <div className="col-md-7 my-2 p-5">
           
            <p className="fs-6">
            Here are some reasons why your road trip from Hyderabad to Vijayawada will be truly special:
            <ul>
              <li>Cultural Stops: Immerse yourself in local culture by visiting iconic stops such as the historic Kanaka Durga Temple, a spiritual haven and architectural marvelE</li>
              <li>Cultural Stops: Immerse yourself in local culture by visiting iconic stops such as the historic Kanaka Durga Temple, a spiritual haven and architectural marvel</li>
              <li>Historical Exploration: Plan a day trip to Amaravathi to explore the Largest Stupa in the country, constructed by Acharya Nagarjuna 2,000 years ago</li>
            </ul> 
            </p>

            
            </div>

            <br />
            <div>
            
            </div>
            </div>
            </div>
            </div> 



            <h2 className="text-center mb-4 fw-bold fs-3 mt-5">
            What Makes Us the Best Preferred Option For<br/> Hyderabad to Vijayawada Taxi?
            </h2>
            <div className="container mt-5">
            {/* Outstation Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt1}
            alt="cab services in vijayawada: outstation"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Clean and Sanitized Cars</h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Each vehicle undergoes thorough cleaning and disinfection
before and after each trip, maintaining a hygienic environment.
This commitment to cleanliness provides peace of mind,
allowing passengers to travel comfortably and confidently.
Experience safe and pleasant rides with SR Car Travels'
meticulously maintained fleet.
            </p> 
            </div>
            </div>

            <hr style={thinLineStyle} />{" "}
            {/* Thin line after Outstation Services Section */}
            {/* Local Cab Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt2}
            alt="Local Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Transparent Billing
            </h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            SR Car Travels provides transparent billing for its Hyderabad to
Vijayawada taxi service. Customers can expect transparent,
upfront pricing with no hidden charges, ensuring a simple and
trustworthy transaction. This commitment to transparency
helps build trust and assures passengers of fair pricing—
making SR Car Travels a reliable choice for intercity travel.
            </p>
            </div>
            </div>
            <hr style={thinLineStyle} />{" "}

            {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt3}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Courteous Chauffers
            </h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Every driver at SR Car Travels is experienced, skilled, and
trained in customer service. This ensures a friendly and
respectful interaction with passengers, prioritizing safety and
comfort while maintaining professionalism throughout the trip.
Whether assisting with luggage, sharing local insights, or
ensuring a smooth ride, their commitment to excellence
enhances your overall travel experience. 
            </p>
            </div>
            </div>

 


            <hr style={thinLineStyle} />{" "}
 {/* Thin line after Local Cab Services Section */}
            {/* Airport Services Section */}
            <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
            src={imagePaths.lpt4}
            alt="Airport Cab Services in Vijayawada"
            effect="blur"
            className="img-fluid rounded"
            />
            </div>
            <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Zero Cancellation Fee
            </h3>
            </div>
            <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Understanding that plans can change unexpectedly, SR Car
Travels permits passengers to cancel their bookings free of
charge. This customer-friendly policy ensures that travelers
can confidently book, knowing they won’t incur financial
penalties if their schedule shifts. Whether a last-minute
alteration or an unforeseen event, SR Car Travels' zero
cancellation fee policy underscores their dedication to
customer satisfaction and convenience, making them a
preferred choice for hassle-free travel. 
            </p>
            </div>
            </div>
            <hr style={thinLineStyle} />{" "}

            </div>



            {/* Thin line after Outstation Services Section */}
            <div className="container pt-5">
            <div className="container-fluid">
            <div className="container ">
            <div className="row align-items-center ">
            {/* Image column */}
            <div className="col-md-5 ">
            <LazyLoadImage
            src={imagePaths.lpn}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid "
            />
            </div>

            {/* Content column */}
            <div className="col-md-7 my-2 ps-3">
            <h3 className="fs-3 fw-bold">
            What Makes SR Car Travels the Ultimate Choice
for Taxi Services
            </h3>
            <p className="fs-6">
            SR Car Travels’ Hyderabad to Vijayawada Taxi Services epitomizes convenience, reliability, and
excellence. Our diverse fleet and flexible options ensure that every travel need is comprehensively
met—whether it's a one-way trip or a round journey. With our dedication to punctuality,
professionalism, cleanliness standards, transparent billing practices, courteous chauffeurs who add
a touch of hospitality to your ride experience—and zero cancellation fees—you’re assured of
unparalleled service. 
            </p>

            <p className="fs-6">
            By choosing SR Car Travels for your intercity transportation needs between Hyderabad and
Vijayawada—or vice versa—you are opting for peace of mind on the road. Revel in scenic
landscapes enriched with cultural heritage as you focus on enjoying the memorable highlights
along this mesmerizing route. Entrust us with making each kilometer count towards an
extraordinary travel adventure. 
<br/>
Book today for your next trip on our <a href="http://srcartravels.in" className="text-dark">website.</a>  
            </p>

            
            <div class="d-flex align-items-center ">
            <a href="tel:+918886556632" class="me-3 text-dark">
            <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
            </a>
            <a href="mailto:contact@srcartravels.in" class="text-dark">
            <i class="bi bi-envelope-fill"></i>
            &nbsp;contact@srcartravels.in
            </a>
            </div>

            <br />
            <div>
            <a
            className="btn btn-info text-white bg-dark "
            style={{ borderRadius: "20px", border: "none" }}
            >
            Know More
            </a>
            </div>
            </div>
            </div>
            </div>
            </div>

            <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

            {/* Accordion Section */}
            <div className="accordion px-5 mx-5">
            {data.map((item, index) => (
            <div key={index} class="py-2">
            <button
            className="accordion-button fs-4 text-dark"
            type="button"
            onClick={() => handleToggle(index)}
            aria-expanded={activeKeys.includes(index) ? "true" : "false"}
            aria-controls={`collapse${index}`}
            >
            <span className="accordion-title">{item.title}</span>
            <span className="accordion-icon">{toggleIcon(index)}</span>
            </button>
            {activeKeys.includes(index) && (
            <div className="accordion-content">{item.content}</div>
            )}
            </div>
            ))}
            </div>
            </div>
        </>
    );
};

export default HyderabadToVijayawadaTaxi;
