import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import "../LandingPages/InsideGuideInNavigatingVijayawada.css";

// Assuming these paths are correct relative to this component file
const imagePaths = {
  bannerImage: require("../assets/img/lp1/l1.png"),
  lp1: require("../assets/img/lp5-1.png"),
  lp2: require("../assets/img/lp5-2.png"),
  lp3: require("../assets/img/lp5-3.png"),
  lp4: require("../assets/img/lp5-4.png"),
  lp5: require("../assets/img/lp5-5.png"),
  lpt1: require("../assets/img/lpt5-1.png"),
  lpt2: require("../assets/img/lpt5-2.png"),
  lpt3: require("../assets/img/lpt5-3.png"),
  lpt4: require("../assets/img/lpt5-4.png"),  
};

// Custom style for the thin line
const thinLineStyle = {
  width: "75%",
  margin: "20px auto", // Combined marginTop and marginBottom
  height: "1px",
  backgroundColor: "#ddd",
};

const data = [
  {
    title:
      "Can I customize my travel itinerary?",
    content:
      "You have the flexibility to customize your itinerary by selecting your preferred route, arranging stops along the way, and determining your departure and return times.",
  },
  {
    title: "Can I book a one-way trip?",
    content:
      " Yes, SR Car Travels provides convenient booking options, including flexible one-way trips from Vijayawada to Hyderabad",
  },
  {
    title: "What safety measures are in place for the journey?",
    content:
      "At SR Car Travels, we prioritize safety through regular vehicle maintenance, GPS tracking, and strict adherence to all safety regulations. Our drivers undergo training to ensure they always follow safe driving practices.",
  },
  {
    title: "Can I cancel or reschedule my booking?",
    content:
      "You can cancel or reschedule your booking. For more specific details, please refer to our cancellation policy on the website or contact our customer support team for personalized assistance.",
  },
  {
    title:
      "What should I do if I need assistance during my trip?",
    content:
      "Our customer support team is available 24/7, ready to assist you with any issues or concerns that may arise during your journey. Feel free to reach out to them anytime for prompt and reliable support.",
  },
];

const bookingOptions = [
  {
    imgSrc: imagePaths.lp2,
    title: "One-Way Service From Vijayawada to Hyderabad",
    text: (
      <>
       Whether you're planning a business trip, a weekend
getaway, or simply need to travel to Hyderabad without
the hassle of returning, our one-way service is the
perfect solution. Enjoy a comfortable and stress-free
journey in one of our well-maintained vehicles, driven
by experienced chauffeurs. With SR Car Travels, you
can relax and enjoy the ride, knowing that your one-way
trip from Vijayawada to Hyderabad is in reliable hands. 
      </>
    ),
  },
  {
    imgSrc: imagePaths.lp3,
    title: "Round Trip Service From Vijayawada to Hyderabad",
    text: (
      <span>
      With <a href="https://srcartravels.in/login">SR Car Travels'</a> round-trip service, you can
eliminate the worry of finding return transportation in
Hyderabad so you can focus on making every moment
count during your time there. With our expert services,
you can discover convenient and hassle-free
exploration for both Vijayawada and Hyderabad. 
      </span>
    ),
  },
  {
    imgSrc: imagePaths.lp4,
    title: "Airport Services",
    text: (
      <span>
    Skip the hassle of steering public transportation or
negotiating with taxi drivers. Instead, pre-book your SR
Car Travels service and be greeted by our polite driver at
the airport arrivals terminal, holding a nameplate for
quick identification. They will assist you with your
luggage and smoothly transport you to your destination
in Vijayawada or Hyderabad, ensuring a seamless and
stress-free transition from the airport to your onward
journey
      </span>
    ),
  } 
];

const InsideGuideInNavigatingVijayawada = ({BannerTitle}) => {
  const [activeKeys, setActiveKeys] = useState(data.map((_, index) => index)); // Keep all toggles open

  const handleToggle = (index) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(index)
          ? prevKeys.filter((key) => key !== index) // Close the accordion if already open
          : [...prevKeys, index], // Open the accordion if it's closed
    );
  };

  const toggleIcon = (index) => {
    return activeKeys.includes(index) ? (
      <i className="fa fa-minus" aria-hidden="true"></i>
    ) : (
      <i className="fa fa-plus" aria-hidden="true"></i>
    );
  };

  return (
    <>
      <Helmet>
        <title>Cabs From Vijayawada to Hyderabad | SR Car Travels</title>
        <meta
          name="description"
          content="SR Travels’ Cabs from Vijayawada to Hyderabad provide easy, secure booking. Choose your car, the best price, and schedule your ride with a few clicks"
        />
        <link
          rel="canonical"
          href="https://srcartravels.in/cabs-from-vijayawada-to-hyderabad"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:site_name" content="SR Car Travels" />
        <meta property="og:url" content="https://srcartravels.in/" />
        <meta
          property="og:title"
          content="Cabs From Vijayawada to Hyderabad | SR Car Travels"
        />
        <meta
          property="og:description"
          content="SR Travels’ Cabs from Vijayawada to Hyderabad provide easy, secure booking. Choose your car, the best price, and schedule your ride with a few clicks"
        />
      </Helmet>
      <div className="banner-contents-container">
        <img
          src={imagePaths.bannerImage}
          className="banner-backdrop d-block w-100"
          loading="lazy"
          alt="Banner"
        />
        <h1 className="mb-3 text-center banner-title-h1">
            {BannerTitle} 
        </h1>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <LazyLoadImage
                src={imagePaths.lp1}
                alt="Car Rentals in Vijayawada"
                effect="blur"
                width="100%"
                height="auto"
                style={{ borderRadius: "25px" }}
                className="img-fluid"
              />
            </div>

            <div className="col-md-7 my-5 py-5 px-5">
            <h2 class="fw-bold">
              Customize Your Outstation Travel
Experience With SR Cabs From
Vijayawada to Hyderabad
              </h2>
              <p className="fs-6 ">
              Welcome to the world of customized travel experiences! Have you ever thought of starting on a
journey that's truly tailored to your every preference and desire? With SR Car Travels, this preference
becomes a reality as you set off from Vijayawada to Hyderabad.
              </p>
              <p className="fs-6">
              Imagine being able to design your own adventure – choosing the route, making stops according to
your needs, and setting the pace of your journey. Our <a href="https://srcartravels.in/outstation">outstation travel service</a> cabs from
Vijayawada to Hyderabad allow you to do just that. 
              </p>
              <p className="fs-6">Whether you need a leisurely exploration of the countryside or a swift and efficient trip straight to
your destination, SR Cabs puts all the control in your hands for an experience like no other. Bid
farewell to one-size-fits-all travel and say hello to a journey as unique as you are. 

Let SR Car Travels take charge as you embark on an adventure designed exclusively for you from
Vijayawada to Hyderabad</p>
            </div>
          </div>
        </div>
      </div>

     

      <div className="container py-4">
        <h2 className="mb-4 text-center fs-3 fw-bold">
        Our Tailored Travel Solutions
        </h2>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {bookingOptions.map((option, index) => (
            <div key={index} className="col">
              <div className="card h-100 border-0">
                <LazyLoadImage
                  src={option.imgSrc} // Now using the correct image for each option
                  alt={option.title}
                  effect="blur"
                  className="card-img px-2"
                />
                <div className="card-body text-left">
                  <h3 className="card-title fs-5">{option.title}</h3>
                  <p className="card-text fs-6">{option.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

       
      </div>



      <h2 className="text-center mb-4 fw-bold fs-3 p-5">
      Customize Your Vijayawada-Hyderabad Trip with<br/>
      SR Car Travels
      </h2>
      <div className="container">
        {/* Outstation Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt1}
              alt="cab services in vijayawada: outstation"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Choose Your Specified Car/
            Vehicle</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Choose from an extensive selection of vehicles, ranging from
sedans and Innovas to luxury cars, all tailored to match your
comfort and style preferences. 
            </p> 
          </div>
        </div>

        <hr style={thinLineStyle} />{" "}
        {/* Thin line after Outstation Services Section */}
        {/* Local Cab Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt2}
              alt="Local Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Schedule Your Departure Time</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
            Set your preferred departure time to perfectly align with your
schedule, whether you prefer an early morning start or a latenight departure. This way, you can ensure that the journey fits
seamlessly into your plans
            </p>
          </div>
        </div>
        <hr style={thinLineStyle} />{" "}

        {/* Thin line after Local Cab Services Section */}
        {/* Airport Services Section */}
        <div className="row justify-content-center align-items-center g-1 my-4">
          <div className="col-lg-2 text-center mb-2">
            <LazyLoadImage
              src={imagePaths.lpt3}
              alt="Airport Cab Services in Vijayawada"
              effect="blur"
              className="img-fluid rounded"
            />
          </div>
          <div className="col-lg-4 text-left mb-2">
            <h3 className="fs-5 fw-bold">Round-trip Flexibility</h3>
          </div>
          <div className="col-lg-4 mb-2">
            <p className="fs-6">
           For a round-trip service, you can customize your return journey
to align with your schedule. We offer flexible timing options to
accommodate your itinerary
            </p>
          </div>
        </div>


        <hr style={thinLineStyle} />{" "}

{/* Thin line after Local Cab Services Section */}
{/* Airport Services Section */}
<div className="row justify-content-center align-items-center g-1 my-4">
  <div className="col-lg-2 text-center mb-2">
    <LazyLoadImage
      src={imagePaths.lpt4}
      alt="Airport Cab Services in Vijayawada"
      effect="blur"
      className="img-fluid rounded"
    />
  </div>
  <div className="col-lg-4 text-left mb-2">
    <h3 className="fs-5 fw-bold">24X7 Support</h3>
  </div>
  <div className="col-lg-4 mb-2">
    <p className="fs-6">
    Feel free to use our round-the-clock customer support for any
last-minute changes or additional requests throughout your
journey. Our team is here to assist you professionally, ensuring
a smooth and stress-free experience.
    </p>
  </div>
</div>


<hr style={thinLineStyle} />{" "}
 
 <p className="text-center px-5 mx-5">
 By following these steps, you can completely personalize your travel experience from Vijayawada to Hyderabad with SR Car Travels.
 This will ensure a journey that caters to all your individual needs and preferences in a professional manner. 
 </p>


      </div>





      {/* Thin line after Outstation Services Section */}
      <div className="container pt-5">
        <div className="container-fluid">
          <div className="container ">
            <div className="row align-items-center ">
              {/* Image column */}
              <div className="col-md-5 ">
                <LazyLoadImage
                  src={imagePaths.lp5}
                  alt="Car Rentals in Vijayawada"
                  effect="blur"
                  width="100%"
                  height="auto"
                  style={{ borderRadius: "25px" }}
                  className="img-fluid "
                />
              </div>

              {/* Content column */}
              <div className="col-md-7 my-2 p-5">
                <h3 className="fs-3 fw-bold">
                Book Your Vijayawada-Hyderabad Ride Without
                Breaking the Bank
                </h3>
                <p className="fs-6">
                SR Travels Cabs from Vijayawada to Hyderabad offers a convenient and secure way to book your
ride. With just a few clicks on the screen, you can select your preferred car at <a href="https://srcartravels.in/sp-offers">the best price</a> and
even schedule your ride for later. SR Car Travel's booking system is both reliable and secure,
ensuring the safety of your personal information
                </p>

                <p className="fs-6">
                SR Car Travels offers a diverse range of cars, including sedans, hatchbacks, and SUVs. The
company prides itself on its punctuality and reliability, guaranteeing that your ride will arrive
promptly. Additionally, its commitment to excellent customer service ensures that every traveler
enjoys a pleasant experience throughout their journey.
                </p>

               

                <div class="d-flex align-items-center ">
                  <a href="tel:+918886556632" class="me-3 text-dark">
                    <i class="bi bi-telephone-fill"></i>&nbsp;+91-8886556632
                  </a>
                  <a href="mailto:contact@srcartravels.in" class="text-dark">
                    <i class="bi bi-envelope-fill"></i>
                    &nbsp;contact@srcartravels.in
                  </a>
                </div>

                <br />
                <div>
                  <a
                    className="btn btn-info text-white bg-dark "
                    style={{ borderRadius: "20px", border: "none" }}
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-center fw-bold fs-2 py-5 mt-5">FAQ</h2>

        {/* Accordion Section */}
        <div className="accordion px-5 mx-5">
          {data.map((item, index) => (
            <div key={index} class="py-2">
              <button
                className="accordion-button fs-4 text-dark"
                type="button"
                onClick={() => handleToggle(index)}
                aria-expanded={activeKeys.includes(index) ? "true" : "false"}
                aria-controls={`collapse${index}`}
              >
                <span className="accordion-title">{item.title}</span>
                <span className="accordion-icon">{toggleIcon(index)}</span>
              </button>
              {activeKeys.includes(index) && (
                <div className="accordion-content">{item.content}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InsideGuideInNavigatingVijayawada;
