import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


// Adjust these image paths according to your project structure
const imagePaths = {
    carRentals : require("../assets/img/sr_travels.png"),
    cityTour: require("../assets/img/hv_img_2.png"),
    luxuryCars: require("../assets/img/hv_img_3.png"),
    customerSatisfaction: require("../assets/img/csv_img_2.png"),
    carServiceTile : require("../assets/img/rs-banner.png"),
    carServices1: require("../assets/img/rs-1.png"),
    carServices2: require("../assets/img/rs-2.png"),
    carServices3: require("../assets/img/rs-3.png"),
    carServices4: require("../assets/img/rs-4.png"),
    serviceHighlights1: require("../assets/img/hl-1.png"),
    serviceHighlights2: require("../assets/img/hl-2.png"),
    serviceHighlights3: require("../assets/img/hl-3.png"),
    serviceHighlights4: require("../assets/img/hl-4.png"),
    serviceHighlights5: require("../assets/img/hl-5.png"),
    serviceHighlights6: require("../assets/img/hl-6.png"),

};

const reviews = [
    { _id: 1, text: "abc" },
    { _id: 2, text: "def" },
    { _id: 3, text: "ghi" },
    { _id: 4, text: "jkl" },
    { _id: 5, text: "mno" },
    { _id: 6, text: "pqr" },
    { _id: 7, text: "stu" },
    { _id: 8, text: "vwx" },
    { _id: 9, text: "yza" }
  ];

const BestCarTravelsVijayawada = () => {
    // Custom style for the thin line
    const thinLineStyle = {
        width: '50%', // Set the width to half of the container
        margin: '0 auto', // Center the line within its container
        height: '1px', // Thin line
        backgroundColor: '#ddd', // Color of the line
        marginTop: '20px', // Space above the line
        marginBottom: '20px', // Space below the line
    };

    return (
        <>
       <div className="container-fluid py-5"> 
        
        <div className="row align-items-center px-5"  style={{ backgroundColor: '#173141', color: '#ffffff' }}>
        <h3 style={{ textAlign: 'center' }} className='pt-5 pb-2 '> 
       <span class="d-md-block d-none"> Best Experience With Our Services or Our Effective SR Car <br/>
        Rental Services in Vijayawada Includes</span>
                </h3>

                <h3 style={{ textAlign: 'center' }} className='pt-2 pb-2 d-block d-md-none'> 
        Best Experience With Our Services or Our Effective SR Car 
        Rental Services in Vijayawada Includes
                </h3>

    {/* Image column */}
    <div className="col-md-6 px-4">
        <LazyLoadImage
            src={imagePaths.carServiceTile}
            alt="Car Rentals in Vijayawada"
            effect="blur"
            width="100%"
            height="auto"
            style={{ borderRadius: "25px" }}
            className="img-fluid "
        />
    </div>

    {/* Content column */}
    <div className="col-md-6 pt-5">
        <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-5">
                <LazyLoadImage
                    src={imagePaths.carServices1}
                    alt="Smooth Airport Transfers in Vijayawada"
                    effect="blur"
                    className="img-fluid rounded"
                />
            </div>
            <div className="col-lg-8 text-center text-md-left mb-2 ">
                <h6 className='fw-bold'>Best Price Guaranteed</h6>
                <p>
                SR <a href="https://srcartravels.in/cars-for-rent-vijayawada">Cars for rent Vijayawada's</a>  Travels guarantees the
                best prices for all your travel needs. Enjoy competitive
                rates and exceptional service.
                </p>
            </div> 
        </div> 


        <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-5">
                <LazyLoadImage
                    src={imagePaths.carServices2}
                    alt="Smooth Airport Transfers in Vijayawada"
                    effect="blur"
                    className="img-fluid rounded"
                />
            </div>
            <div className="col-lg-8 text-center text-md-left mb-2">
                <h6 className='fw-bold'>24/7 Customer Service</h6>
                <p>
                We offer 24/7 customer service, ensuring support and
                assistance anytime you need it. Our dedicated team is
                always ready to help. 
                </p>
            </div> 
        </div> 


        <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-5">
                <LazyLoadImage
                    src={imagePaths.carServices3}
                    alt="Smooth Airport Transfers in Vijayawada"
                    effect="blur"
                    className="img-fluid rounded"
                />
            </div>
            <div className="col-lg-8 text-center text-md-left mb-2">
                <h6 className='fw-bold'>Simple Booking Process</h6>
                <p>
                Choose your vehicle, select dates, and confirm. Enjoy a
                quick, hassle-free experience with just a few clicks.
                </p>
            </div> 
        </div> 


        <div className="row justify-content-center align-items-center g-1 my-4">
            <div className="col-lg-2 text-center mb-5">
                <LazyLoadImage
                    src={imagePaths.carServices4}
                    alt="Smooth Airport Transfers in Vijayawada"
                    effect="blur"
                    className="img-fluid rounded"
                />
            </div>
            <div className="col-lg-8 text-center text-md-left mb-2">
                <h6 className='fw-bold'>Go Anywhere in Vijayawada</h6>
                <p>
                Our car rental service ensures you can go anywhere in
                the city with ease, comfort, and convenience. 
                </p>
            </div> 
        </div> 
    </div>


        </div> 

       </div>


       <h3 className='pt-3 pb-md-2 pb-0 text-center'>SR Car Travels Highlights</h3>
        
        <div className="container pb-5 mt-md-5 mb-md-5 mt-0 mb-0">  

        <div className="row"> 
 
            <div className="col-md-4 pt-md-5 pt-3">  

                <div class="container py-3">
                    <div class="card border-0">
                        <div class="row ">
                            <div class="col-md-3">
                                <img src={imagePaths.serviceHighlights1} class="w-md-100"/>
                            </div>
                            <div class="col-md-9 ">
                                <div class="card-block ">
                                <h5 class="card-title d-md-block d-none">Well-maintained<br/> Car fleet</h5>
                                <h5 class="card-title d-md-none d-block pt-3">Well-maintained Car fleet</h5>
                                <p class="card-text text-muted">Explore our <a href="https://srcartravels.in/best-car-travels-in-vijayawada">best car travels in Vijayawada’s</a> range of versatile vehicles, perfect for city commutes and outstation trips.</p> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 


                <div class="container py-3">
                    <div class="card border-0">
                        <div class="row ">
                            <div class="col-md-3">
                                <img src={imagePaths.serviceHighlights2} class="w-md-100"/>
                            </div>
                            <div class="col-md-9 ">
                                <div class="card-block ">
                                <h5 class="card-title d-md-block d-none">Customer <br/>focus</h5>
                                <h5 class="card-title d-block d-md-none pt-3">Customer focus</h5>
                                <p class="card-text text-muted">We are dedicated to understanding and addressing your needs and providing exceptional service.</p> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 
         
            </div> 

            <div className="col-md-4 pt-md-5 pt-3"> 

                <div class="container py-3">
                        <div class="card border-0">
                            <div class="row ">
                                <div class="col-md-3">
                                    <img src={imagePaths.serviceHighlights3} class="w-md-100"/>
                                </div>
                                <div class="col-md-9 ">
                                    <div class="card-block ">
                                    <h5 class="card-title d-md-block d-none">Experienced <br/> drivers</h5>
                                    <h5 class="card-title d-block d-md-none pt-3">Experienced drivers</h5>
                                    <p class="card-text text-muted">
                                    Our professional and courteous drivers are dedicated to ensuring your journeys are smooth and enjoyable.
                                    </p> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                </div> 


                <div class="container py-3">
                    <div class="card border-0">
                        <div class="row ">
                            <div class="col-md-3">
                                <img src={imagePaths.serviceHighlights4} class="w-md-100"/>
                            </div>
                            <div class="col-md-9 ">
                                <div class="card-block ">
                                <h5 class="card-title d-md-block d-none">One way packages from<br/>
                                Vijayawada to Hyderabad</h5>
                                <h5 class="card-title d-md-none d-block pt-3">One way packages from Vijayawada to Hyderabad</h5>
                                <p class="card-text text-muted">
                                Simplify your travel plans and save money with our convenient one-way packages from Vijayawada to Hyderabad.
                                </p> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>  

            </div> 

            <div className="col-md-4 pt-md-5 pt-3"> 
            <div class="container py-3">
                        <div class="card border-0">
                            <div class="row ">
                                <div class="col-md-3">
                                    <img src={imagePaths.serviceHighlights5} class="w-md-100"/>
                                </div>
                                <div class="col-md-9 ">
                                    <div class="card-block ">
                                    <h5 class="card-title d-md-block d-none">City tours & outstation
                                    <br/>packages</h5>
                                    <h5 class="card-title d-block d-md-none pt-3">City tours & outstation
                                    <br/>packages</h5>
                                    <p class="card-text text-muted">
                                    Explore the city with our curated city tours, or venture beyond with our diverse outstation packages.
                                    </p> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                </div> 


                <div class="container py-3">
                    <div class="card border-0">
                        <div class="row ">
                            <div class="col-md-3">
                                <img src={imagePaths.serviceHighlights6} class="w-md-100"/>
                            </div>
                            <div class="col-md-9 ">
                                <div class="card-block ">
                                <h5 class="card-title d-md-block d-none">Airport <br/>Pick ups</h5>
                                <h5 class="card-title d-md-none d-block pt-3">Airport Pick ups</h5>
                                <p class="card-text text-muted">
                                We provide streamlined airport pick-up services, ensuring a hassle-free journey from arrival to your destination.
                                </p> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div> 


            </div> 
 
        </div>
            
        </div>


       



        </>
    );
};

export default BestCarTravelsVijayawada;
